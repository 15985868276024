import { ArticleListResponseVo } from '@schemas/non-auth'
import { theme } from '@ui/style'
import { pxToRem } from '@ui/style/muiTheme'
import Grid from '@mui/joy/Grid'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import UIDivider from '@components/ui/UIDivider'
import { getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import { getArticleLink } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'

const ArticleItem = ({
	item,
	index,
	isDark,
	areaIdx,
}: {
	item: ArticleListResponseVo
	index: number
	isDark?: boolean
	areaIdx?: number
}) => {
	const mainCardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			layoutGap: pxToRem(16),
			layoutSx: {
				alignItems: 'flex-start',
				[theme.breakpoints.down('md')]: {
					paddingBottom: pxToRem(12),
				},
			},
		},
		thumbnailConfig: {
			thumbWidth: { xs: '100%', md: pxToRem(224), lg: pxToRem(288), xl: pxToRem(384) },
			thumbHeight: { md: pxToRem(126), lg: pxToRem(162), xl: pxToRem(216) },
			badgeInset: pxToRem(6),
		},
		infoConfig: {
			titleLevel: 'HeadingXL',
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(18),
						xl: pxToRem(20),
					},
					lineHeight: {
						xs: pxToRem(24),
						xl: pxToRem(28),
					},
				},
				'.info-additionalBox': {
					marginTop: { xs: pxToRem(8), xl: pxToRem(12) },
				},
			},
			isDark,
		},
	}

	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: { xs: 'row-reverse', md: 'column' },
			layoutGap: pxToRem(16),
			layoutSx: {
				alignItems: 'flex-start',
			},
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(120), md: pxToRem(224), lg: pxToRem(288), xl: pxToRem(384) },
			thumbHeight: { xs: pxToRem(68), md: pxToRem(126), lg: pxToRem(162), xl: pxToRem(216) },
			badgeInset: pxToRem(6),
		},
		infoConfig: {
			titleLevel: 'HeadingXL',
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						lg: pxToRem(18),
						xl: pxToRem(20),
					},
					lineHeight: {
						xs: pxToRem(22),
						lg: pxToRem(24),
						xl: pxToRem(28),
					},
				},
				'.info-additionalBox': {
					marginTop: { xs: pxToRem(8), xl: pxToRem(12) },
				},
			},
			isDark,
		},
	}
	const image = item.vodInfo ? item.vodInfo?.vodThumbnailImgUrl : item?.articleThumbnailImgUrl

	return (
		<>
			{index > 0 && (
				<UIDivider
					orientation={{ xs: 'horizontal', md: 'vertical' }}
					isDark={isDark}
					sx={
						index % 3 === 0
							? {
									display: { md: 'none' },
							  }
							: undefined
					}
				/>
			)}
			<Grid xs={12} md={4}>
				<ContentCard
					content={{
						articleIdx: item?.articleIdx,
						title: item.articleTitle,
						href: getArticleLink(item, 'HOME', areaIdx),
						src: image ?? undefined,
						body: !image ? item.articleInnerTextContent ?? undefined : undefined,
						alt: '',
						additionalText: getRelativeTime(item?.publicationDate ?? ''),
						duration: item?.vodInfo?.playTime,
						bullet: item?.bulletType && item.bulletType.name,
						bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
						vodInfo: item?.vodInfo,
					}}
					{...(index === 0 ? mainCardProps : cardProps)}
				/>
			</Grid>
		</>
	)
}
export default ArticleItem

'use client'

import UIFlex, { UIFlexProps } from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { Box } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

interface HorizontalTimelineItemProps extends Omit<UIFlexProps, 'ref'> {
	time?: string
	title: string
	href: string
	isDark?: boolean
}
const HorizontalTimelineItem = ({ time, title, href, isDark }: HorizontalTimelineItemProps) => {
	return (
		<UIFlex sx={{ '&> *:not(:first-child)': { marginTop: pxToRem(18) } }}>
			<Box
				pb={pxToRem(26)}
				pl={pxToRem(4)}
				position="relative"
				sx={{
					'&::before': {
						content: '""',
						display: 'block',
						width: pxToRem(8),
						height: pxToRem(8),
						background: color.colWhite,
						borderRadius: '9999px',
						position: 'absolute',
						left: 0,
						bottom: 0,
					},
					'&::after': {
						content: '""',
						display: 'block',
						width: `calc(100% - ${pxToRem(16)})`,
						height: '1px',
						background: color.colWhite,
						opacity: 0.3,
						borderRadius: '2px',
						position: 'absolute',
						left: pxToRem(12),
						bottom: pxToRem(3.5),
					},
				}}
			>
				{time && (
					<Box
						component={UIText}
						display="inline-flex"
						position="relative"
						padding={`${pxToRem(6)} ${pxToRem(10)}`}
						fontSize={pxToRem(14)}
						lineHeight={pxToRem(18)}
						fontWeight={500}
						sx={{
							background: color.colMintBlue,
							color: color.colWhite,
							'&::after': {
								content: '""',
								display: 'block',
								position: 'absolute',
								left: 0,
								top: 'calc(100% - 1px)',
								width: 0,
								height: 0,
								borderTop: `${pxToRem(6)} solid ${color.colMintBlue}`,
								borderBottom: `${pxToRem(6)} solid transparent`,
								borderLeft: `${pxToRem(6)} solid ${color.colMintBlue}`,
								borderRight: `${pxToRem(6)} solid transparent`,
							},
						}}
					>
						{time}
					</Box>
				)}
			</Box>
			<UIFlex
				alignItems="flex-start"
				sx={{ '&> *:not(:first-child)': { marginTop: pxToRem(8) } }}
				paddingRight={{
					xs: pxToRem(24),
					md: pxToRem(32),
				}}
				{...{ component: UILink, href }}
			>
				<UIText
					color={isDark ? color.colWhite : color.colBlack}
					lineClamp={2}
					fontWeight={700}
					fontSize={{ xs: pxToRem(16), md: pxToRem(17), xl: pxToRem(18) }}
					sx={{ lineHeight: { xs: pxToRem(22), md: pxToRem(24), xl: pxToRem(26) } }}
				>
					{title}
				</UIText>
				<UIText
					color={isDark ? color.colWhite : color.colBlack}
					level="CaptionTime"
					sx={{ opacity: 0.6, lineHeight: pxToRem(18) }}
				>
					자세히 보기
				</UIText>
			</UIFlex>
		</UIFlex>
	)
}

export default HorizontalTimelineItem

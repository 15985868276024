import { ArticleListResponseVo } from '@schemas/non-auth'
import { pxToRem } from '@ui/style/muiTheme'
import color from '@ui/style/color.theme'
import Box from '@mui/joy/Box'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import UIText from '@components/ui/UIText'
import UIFlex from '@components/ui/UIFlex'
import { CSSGridListBorder } from '@components/list/CSSgridList/CSSgridListRoot'
import { getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import CSSgridList from '@components/list/CSSgridList'
import { getArticleLink } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'

const ListRankingNews = ({
	list = [],
	isDark,
	areaIdx,
}: {
	list?: Array<ArticleListResponseVo>
	isDark?: boolean
	areaIdx?: number
}) => {
	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: 'row-reverse',
			layoutGap: pxToRem(16),
			layoutSx: {
				alignItems: 'stretch',
				height: {
					md: pxToRem(67.5),
					lg: pxToRem(74.25),
				},
			},
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(120), lg: pxToRem(132) },
			thumbHeight: { xs: pxToRem(68), lg: pxToRem(74) },
			badgeInset: pxToRem(6),
		},
		infoConfig: {
			titleLevel: 'HeadingM',
			infoSx: {
				'.info-title': {
					marginBottom: pxToRem(8),
				},
				'.info-additionalBox': {
					marginTop: 'auto',
				},
			},
			isDark,
		},
	}
	const drawRankingNewsItem = (item: ArticleListResponseVo, index: number) => (
		<Box
			className="article-item"
			key={`article-${item.articleIdx}-${item.publicationDate}-${areaIdx}`}
		>
			<UIFlex
				className="item-inbox"
				flexDirection="row"
				sx={{
					'&>*:not(:first-child)': {
						marginLeft: pxToRem(12),
					},
				}}
			>
				<Box
					width={{
						xs: pxToRem(24),
						md: pxToRem(20),
						lg: pxToRem(24),
					}}
					flex="none"
				>
					<UIText
						fontSize={{
							xs: pxToRem(24),
							md: pxToRem(26),
							lg: pxToRem(28),
						}}
						lineHeight={{
							xs: pxToRem(26),
							lg: pxToRem(28),
						}}
						fontWeight={600}
						color={color.colMintBlue}
					>
						{index + 1}
					</UIText>
				</Box>
				<ContentCard
					content={{
						articleIdx: item?.articleIdx,
						title: item.articleTitle,
						href: getArticleLink(item, 'HOME', areaIdx),
						src:
							(item.vodInfo
								? item.vodInfo?.vodThumbnailImgUrl
								: item?.articleThumbnailImgUrl) ?? imgDefaultH,
						alt: '',
						additionalText: getRelativeTime(item?.publicationDate ?? ''),
						duration: item?.vodInfo?.playTime,
						bullet: item?.bulletType && item.bulletType.name,
						bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
						showBookmark: false,
						isBookmarked: item.isScrap ?? false, // 수정 필요
						vodInfo: item?.vodInfo,
					}}
					layoutConfig={cardProps.layoutConfig}
					thumbnailConfig={cardProps.thumbnailConfig}
					infoConfig={cardProps.infoConfig}
				/>
			</UIFlex>
		</Box>
	)
	return (
		<CSSgridList
			gridTemplateRows={{ md: 'repeat(4, 1fr)', xl: 'repeat(3, 1fr)' }}
			gridTemplateColumns={{ xs: '1fr', md: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }}
			gridAutoFlow={{ xs: 'row', md: 'column' }}
			isDark={isDark}
			sx={(theme) => ({
				'& .item-inbox > *:last-child': {
					flexGrow: 1,
				},
				[theme.breakpoints.down('md')]: {
					...CSSGridListBorder('horizontal', isDark),
					'& .article-item:nth-child(5)': {
						borderBottom: 0,
						paddingBottom: 0,
					},
					'& .article-item:nth-child(5) ~ .article-item': {
						display: 'none',
					},
				},
				[theme.breakpoints.down('xl')]: {
					'& .article-item:nth-child(9)': {
						display: 'none',
					},
				},
				[theme.breakpoints.up('md')]: {
					...CSSGridListBorder('both', isDark),
				},
			})}
		>
			{list.sort((a, b) => a.rank - b.rank).map(drawRankingNewsItem)}
		</CSSgridList>
	)
}

export default ListRankingNews

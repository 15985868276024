'use client'

import Carousel from '@components/common/Carousel/Carousel'
import { Box } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import UILink from '@components/ui/UILink'
import { BannerVo } from '@schemas/non-auth'
import { uuidv4 } from '@utils/random'
import UIImage from '@components/ui/UIImage'

const ListBanner = ({ list = [], areaIdx }: { list?: Array<BannerVo>; areaIdx?: number }) => {
	return (
		<Box mx={{ xs: pxToRem(-20), md: 0 }}>
			<Carousel
				pagination={list.length > 1}
				navigation
				slidesPerView={1}
				breakpoints={{
					768: {
						spaceBetween: 16,
					},
					1024: {
						spaceBetween: 16,
					},
				}}
			>
				{list?.map((bannerInfo) => (
					<Box
						px={{ xs: pxToRem(20), md: 0 }}
						key={`banner-${bannerInfo.bannerIdx}-${uuidv4()}`}
					>
						<Box
							overflow="hidden"
							borderRadius={pxToRem(8)}
							display={{ xs: 'none', md: 'block' }}
							{...{
								component: UILink,
								link: bannerInfo.pcLinkUrlInfo,
								query: { influxDiv: 'JTBC', areaDiv: 'HOME', areaIdx },
							}}
							height={pxToRem(100)}
						>
							<UIImage
								src={bannerInfo.pcThumbnailImgUrl}
								alt={bannerInfo.bannerTitle}
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									objectPosition: 'center',
								}}
							/>
						</Box>
						<Box
							overflow="hidden"
							borderRadius={pxToRem(8)}
							display={{ xs: 'block', md: 'none' }}
							{...{
								component: UILink,
								link: bannerInfo.moLinkUrlInfo,
								query: { influxDiv: 'JTBC', areaDiv: 'HOME', areaIdx },
							}}
							height={pxToRem(100)}
						>
							<UIImage
								src={bannerInfo.moThumbnailImgUrl}
								alt={bannerInfo.bannerTitle}
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									objectPosition: 'center',
								}}
							/>
						</Box>
					</Box>
				))}
			</Carousel>
		</Box>
	)
}

export default ListBanner

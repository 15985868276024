import { ApiResultVoSeriesOrCornerAreaResponseVo } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { NewHomeReqModel } from '../types'
import { fetchAPI } from '@utils/http/apiRequest'

export type SeriesCornerResponse = CommonResponse<ApiResultVoSeriesOrCornerAreaResponseVo>
export type SeriesCornerResponseData = ApiResultVoSeriesOrCornerAreaResponseVo['data']

const path = '/restapi/v1/get/template/news-home/series-corner'
const queryKeyString = ['@template/news-home/series-corner'] as const

const getQuery = async (
	props: NewHomeReqModel,
	cookieHeader?: string,
): Promise<ApiResultVoSeriesOrCornerAreaResponseVo> => {
	const { areaIdx } = props
	const params = new URLSearchParams({
		...(areaIdx ? { areaIdx: String(areaIdx) } : {}),
	})
	return fetchAPI({ path, params, cookieHeader })
}

export const querySeriesCorner = (props: NewHomeReqModel, cookieHeader?: string) => ({
	queryKey: [...queryKeyString],
	queryFn: () => getQuery(props, cookieHeader),
})

export const prefetchSeriesCorner = (props: NewHomeReqModel, cookieHeader?: string) =>
	createPrefetchQuery(queryKeyString, () => getQuery(props, cookieHeader))

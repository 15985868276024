'use client'

import Carousel from '@components/common/Carousel/Carousel'
import { Box, BoxProps as MuiBoxProps } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import { CSSProperties } from 'react'

interface CarouselListProps extends MuiBoxProps {
	children: React.ReactNode
	noSpaceBetween?: boolean
}
const CarouselList = ({ children, noSpaceBetween = false, ...props }: CarouselListProps) => {
	const carouselConfig = {
		slidesPerView: 'auto' as const,
		spaceBetween: noSpaceBetween ? 0 : 16,
		navigation: false,
		slidesOffsetBefore: 20,
		slidesOffsetAfter: 20,
		breakpoints: {
			768: {
				slidesPerView: 3,
				spaceBetween: noSpaceBetween ? 0 : 24,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
			},
			1024: {
				slidesPerView: 4,
				spaceBetween: noSpaceBetween ? 0 : 32,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
			},
		},
	}
	return (
		<Box
			{...props}
			mx={{ xs: pxToRem(-20), md: 0 }}
			sx={(theme) => ({
				...(typeof props.sx === 'function' ? props.sx(theme) : (props.sx as CSSProperties)),
				'& .swiper:not(.swiper-initialized)': {
					[theme.breakpoints.down('md')]: {
						'& .swiper-wrapper': {
							paddingLeft: theme.pxToRem(20),
							paddingRight: theme.pxToRem(20),
							'&>*:not(:first-child)': {
								marginLeft: theme.pxToRem(noSpaceBetween ? 0 : 16),
							},
						},
						// '& .swiper-slide': {
						// 	width: `calc((100% - ${theme.pxToRem(
						// 		noSpaceBetween ? 0 : 16 * 2,
						// 	)}) / 1.6)`,
						// },
					},
					[theme.breakpoints.up('md')]: {
						'& .swiper-wrapper': {
							'&>*:not(:first-child)': {
								marginLeft: theme.pxToRem(noSpaceBetween ? 0 : 24),
							},
						},
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(
								noSpaceBetween ? 0 : 24 * 2,
							)}) / 3)`,
						},
					},
					[theme.breakpoints.up('lg')]: {
						'& .swiper-wrapper': {
							'&>*:not(:first-child)': {
								marginLeft: theme.pxToRem(noSpaceBetween ? 0 : 32),
							},
						},
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(
								noSpaceBetween ? 0 : 32 * 3,
							)}) / 4)`,
						},
					},
					[theme.breakpoints.up('xl')]: {
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(
								noSpaceBetween ? 0 : 32 * 3,
							)}) / 4)`,
						},
					},
				},
				'& .swiper-slide:nth-child(3) ~ .swiper-slide': {
					[theme.breakpoints.only('md')]: {
						display: 'none',
					},
				},
			})}
		>
			<Carousel {...carouselConfig}>{children}</Carousel>
		</Box>
	)
}

export default CarouselList

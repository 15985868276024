'use client'

import UIFlex from '@components/ui/UIFlex'
import SectionDetailTitle from '@components/common/Section/SectionDetailTitle'
import ListOnBg from '@components/list/ListOnBg'
import { IssueInfo } from '@schemas/non-auth'
import dayjs from 'dayjs'
import { DATE_FORMAT, getRelativeTime } from '@utils/date'
import { ArticleItemProps } from '..'
import { getAreaParams, getArticleLink, getPackageLinkType } from '@utils/getLink'
import SectionTitle from '@components/common/Section/SectionTitle'
import { theme } from '@ui/style'
import pxToRem from '@utils/pxToRem'

const ListOnBgHighlight = ({
	type,
	list = [],
	issue,
	isDark,
	areaIdx,
}: {
	type?: string
	list: Array<ArticleItemProps>
	issue?: IssueInfo
	isDark?: boolean
	areaIdx?: number
}) => {
	const [article1st, ...articleList] = list
	const issueList = issue?.issueItemList?.map(({ articleInfo, issueItemCode, liveInfo }) =>
		issueItemCode === 'ARTICLE'
			? {
					...articleInfo,
					code: issueItemCode,
			  }
			: {
					articleInnerTextContent: null,
					articleIdx: liveInfo.liveIdx,
					articleThumbnailImgUrl: liveInfo.liveThumbnailImgUrl,
					articleTitle: liveInfo.liveTitle,
					bulletType: liveInfo.bulletType,
					isScrap: null,
					isVideoView: false,
					journalistName: '',
					publicationDate: liveInfo.liveStartDate,
					rank: 0,
					code: issueItemCode,
			  },
	)
	const packageCode = issue?.packageCode?.code
	const packageTitle = issue?.packageName
	const isPackageTitle = packageTitle && packageTitle !== ''
	return (
		<UIFlex mb={{ xs: pxToRem(-10), md: pxToRem(-12), lg: pxToRem(-6), xl: pxToRem(0) }}>
			{article1st && (
				<SectionDetailTitle
					href={getArticleLink(article1st, 'HOME', areaIdx)}
					title={article1st?.articleTitle ?? ''}
					body={type !== 'live' ? article1st?.articleInnerTextContent ?? '' : undefined}
					additionalText={getRelativeTime(
						article1st?.publicationDate ?? '',
						type === 'live',
					)}
					isLive={article1st?.code === 'LIVE'}
					isDark={isDark}
					bullet={article1st?.bulletType.name}
					sx={{
						marginTop: {
							xs: pxToRem(8),
							md: pxToRem(-14),
							lg: pxToRem(9),
							xl: pxToRem(50),
						},
						marginBottom: isPackageTitle
							? {
									xs: pxToRem(28),
									md: pxToRem(30),
									lg: pxToRem(40),
									xl: pxToRem(35),
							  }
							: {
									xs: pxToRem(42),
									md: pxToRem(32),
									lg: pxToRem(64),
									xl: pxToRem(64),
							  },
					}}
				/>
			)}
			{isPackageTitle && (
				<SectionTitle
					isDark={isDark}
					{...(packageCode && packageCode !== 'SPECIAL'
						? {
								showGoButton: true,
								href: `/${getPackageLinkType(packageCode)}/${
									issue.packageIdx
								}?${getAreaParams('HOME', areaIdx)}`,
						  }
						: {})}
					sx={{
						[theme.breakpoints.only('md')]: {
							paddingTop: 0,
							paddingBottom: pxToRem(22),
						},
					}}
				>
					{packageTitle}
				</SectionTitle>
			)}
			<ListOnBg
				list={
					(type === 'live'
						? [...(articleList ?? []), ...(issueList ?? [])]
						: articleList) as Array<ArticleItemProps>
				}
				isDark={isDark}
				areaIdx={areaIdx}
			/>
		</UIFlex>
	)
}

export default ListOnBgHighlight

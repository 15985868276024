'use client'

import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import { Grid } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import { ArticleItemProps } from '..'
import { createXNCardContent } from '../createContent'
import UIDivider from '@components/ui/UIDivider'

const List3XN = ({
	list = [],
	isDark,
	areaIdx,
	packageIdx,
	isVideoNews = false,
}: {
	list: Array<ArticleItemProps>
	isDark?: boolean
	areaIdx?: number
	packageIdx?: string
	isVideoNews?: boolean
}) => {
	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: { xs: 'row', md: 'column' },
			layoutGap: pxToRem(16),
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(162), md: pxToRem(224), lg: pxToRem(288), xl: pxToRem(384) },
			thumbHeight: { xs: pxToRem(92), md: pxToRem(126), lg: pxToRem(162), xl: pxToRem(216) },
			badgeInset: pxToRem(8),
		},
		infoConfig: {
			isDark,
			infoGap: {
				xs: pxToRem(8),
				xl: pxToRem(12),
			},
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(15),
						md: pxToRem(16),
						lg: pxToRem(18),
						xl: pxToRem(20),
					},
					lineHeight: {
						xs: pxToRem(22),
						lg: pxToRem(24),
						xl: pxToRem(28),
					},
				},
				'.info-additionalText': {
					fontSize: {
						xs: pxToRem(13),
						md: pxToRem(14),
					},
					lineHeight: {
						xs: pxToRem(16),
						md: pxToRem(18),
					},
				},
			},
		},
	}
	const drawItem = (item: ArticleItemProps, index: number) => {
		return (
			<>
				{index !== 0 && index % 3 !== 0 && (
					<UIDivider
						orientation="vertical"
						sx={{ display: { xs: 'none', md: 'block' } }}
						isDark={isDark}
					/>
				)}
				<Grid xs={12} md={4} key={`${item.articleIdx}-${item.articleTitle}`}>
					<ContentCard
						content={createXNCardContent({
							item,
							areaIdx,
							packageIdx,
							isVideoNews,
						})}
						layoutConfig={cardProps.layoutConfig}
						thumbnailConfig={cardProps.thumbnailConfig}
						infoConfig={cardProps.infoConfig}
					/>
				</Grid>
			</>
		)
	}
	return (
		<Grid
			container
			columnSpacing={{ xs: 0, md: pxToRem(24), lg: pxToRem(32) }}
			mt={{
				xs: pxToRem(-16),
				md: pxToRem(-32),
				xl: pxToRem(-36),
			}}
			sx={{
				'&>*': {
					marginTop: {
						xs: `${pxToRem(16)}!important`,
						md: `${pxToRem(32)}!important`,
						xl: `${pxToRem(36)}!important`,
					},
				},
			}}
		>
			{list.map(drawItem)}
		</Grid>
	)
}

export default List3XN

'use client'

import { CornerTagAreaItem } from '@schemas/non-auth'
import { pxToRem } from '@ui/style/muiTheme'
import Box from '@mui/joy/Box'
import Carousel from '@components/common/Carousel/Carousel'
import CornerItem from './CornerItem'
import { getAreaParams } from '@utils/getLink'

const ListRecommendedCorner = ({
	list = [],
	areaIdx,
}: {
	list?: Array<CornerTagAreaItem>
	areaIdx?: number
}) => {
	const drawCornerItem = (item: CornerTagAreaItem) => (
		<CornerItem
			key={`item-${item.packageIdx}-${item.cornerTagIdx}-${areaIdx}`}
			title={item.cornerTagTitle}
			description={`${item.articleCnt}개의 뉴스`}
			href={`/series/${item.packageIdx}?${getAreaParams('HOME', areaIdx)}`}
			src={item.thumbnailImgUrl ?? ''}
			alt=""
			packageIdx={item.packageIdx}
			isSubscribed={item?.isSubscribe ?? false}
		/>
	)
	return (
		<Box
			sx={(theme) => ({
				marginX: pxToRem(-20),

				'& .swiper:not(.swiper-initialized)': {
					[theme.breakpoints.down('md')]: {
						'& .swiper-wrapper': {
							paddingLeft: theme.pxToRem(20),
							paddingRight: theme.pxToRem(20),
							'&> *:not(:first-child)': { marginLeft: pxToRem(16) },
						},
					},
					[theme.breakpoints.up('md')]: {
						'& .swiper-wrapper': {
							'&> *:not(:first-child)': { marginLeft: pxToRem(24) },
						},
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(24 * 2)}) / 3)`,
						},
					},
					[theme.breakpoints.up('xl')]: {
						'& .swiper-wrapper': {
							'&> *:not(:first-child)': { marginLeft: pxToRem(32) },
						},
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(20 * 3)}) / 4)`,
						},
					},
				},

				[theme.breakpoints.down('md')]: {
					'& .swiper-slide': {
						width: `${pxToRem(264)}!important`,
					},
				},
				[theme.breakpoints.up('md')]: {
					marginX: pxToRem(0),

					'.swiper-button-prev, .swiper-button-next': {
						top: pxToRem(88),
					},
				},
				[theme.breakpoints.up('lg')]: {
					'.swiper-button-prev, .swiper-button-next': {
						top: pxToRem(120),
					},
				},
				[theme.breakpoints.up('xl')]: {
					'.swiper-button-prev, .swiper-button-next': {
						top: pxToRem(116),
					},
				},
			})}
		>
			<Carousel
				slidesPerView="auto"
				spaceBetween={16}
				slidesOffsetBefore={20}
				slidesOffsetAfter={20}
				navigation
				breakpoints={{
					768: {
						slidesPerView: 3,
						spaceBetween: 24,
						slidesOffsetBefore: 0,
						slidesOffsetAfter: 0,
					},
					1280: {
						slidesPerView: 4,
						spaceBetween: 32,
						slidesOffsetBefore: 0,
						slidesOffsetAfter: 0,
					},
				}}
			>
				{list?.map(drawCornerItem)}
			</Carousel>
		</Box>
	)
}

export default ListRecommendedCorner

import { ArticleListResponseVo } from '@schemas/non-auth'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import { pxToRem } from '@ui/style/muiTheme'
import UIDivider from '@components/ui/UIDivider'
import { getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import { getArticleLink } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'
import { theme } from '@ui/style'

const ArticleItem = ({
	item,
	index,
	isDark,
	areaIdx,
}: {
	item: ArticleListResponseVo
	index: number
	isDark?: boolean
	areaIdx?: number
}) => {
	const mainCardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			layoutGap: { xs: pxToRem(12), xl: pxToRem(16) },
		},
		thumbnailConfig: {
			badgeInset: pxToRem(6),
			thumbWidth: {
				xs: pxToRem(288),
				md: pxToRem(344),
				lg: pxToRem(288),
				xl: pxToRem(373.33),
			},
			thumbHeight: { xs: pxToRem(162), md: pxToRem(194), lg: pxToRem(162), xl: pxToRem(216) },
		},
		infoConfig: {
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						lg: pxToRem(17),
						xl: pxToRem(20),
					},
					lineHeight: {
						xs: pxToRem(22),
						lg: pxToRem(24),
						xl: pxToRem(28),
					},
					height: {
						xs: pxToRem(44),
						lg: pxToRem(48),
						xl: pxToRem(56),
					},
				},
			},
			isDark,
		},
	}
	const subCardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: 'row-reverse',
			layoutGap: { xs: pxToRem(12), xl: pxToRem(16) },
			layoutSx: {
				height: {
					md: pxToRem(68),
					lg: pxToRem(74),
					xl: pxToRem(74.25),
				},
			},
		},
		thumbnailConfig: {
			badgeInset: pxToRem(6),
			thumbRatio: '16/9',
			thumbWidth: { md: pxToRem(120), xl: pxToRem(132) },
			thumbHeight: { md: pxToRem(68), xl: pxToRem(74) },
			thumbSx: {
				display: {
					xs: 'none',
					md: 'block',
					lg: 'none',
					xl: 'block',
				},
			},
		},
		infoConfig: {
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						lg: pxToRem(17),
					},
					lineHeight: {
						xs: pxToRem(22),
						lg: pxToRem(24),
					},
					marginBottom: pxToRem(8),
				},
				'.info-additionalBox': {
					marginTop: 'auto',
				},
			},
			isDark,
		},
	}
	return (
		<>
			{index > 0 && <UIDivider orientation="horizontal" isDark={isDark} />}
			<ContentCard
				key={`article-news-${item.articleIdx}-${areaIdx}`}
				content={{
					articleIdx: item?.articleIdx,
					title: item.articleTitle,
					href: getArticleLink(item, 'HOME', areaIdx),
					src: item.vodInfo
						? item.vodInfo?.vodThumbnailImgUrl
						: item?.articleThumbnailImgUrl ?? undefined,
					alt: '',
					additionalText: getRelativeTime(item?.publicationDate ?? ''),
					duration: item?.vodInfo?.playTime,
					bullet: item?.bulletType && item.bulletType.name,
					bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
					showBookmark: true,
					isBookmarked: item.isScrap ?? false, // 수정 필요
					vodInfo: item?.vodInfo,
				}}
				layoutConfig={{
					...(index > 0 ? subCardProps : mainCardProps).layoutConfig,
				}}
				thumbnailConfig={{
					...(index > 0 ? subCardProps : mainCardProps).thumbnailConfig,
				}}
				infoConfig={{
					...(index > 0 ? subCardProps : mainCardProps).infoConfig,
				}}
			/>
		</>
	)
}
export default ArticleItem

import { SubscribeButton } from '@components/button/SubscribeButton'
import ContentBadge, { ContentType } from '@components/content/ContentBadge'
import { pxToRem } from '@ui/style/muiTheme'
import { getAreaParams } from '@utils/getLink'
import {
	Container,
	ContentBadgeBox,
	ReporterAvatar,
	SubTitle,
	TextBox,
	Title,
	WrapperLink,
} from './ReporterInfoBox.styled'
import UIFlex from '@components/ui/UIFlex'
import IconNotificationFallbackImage from 'src/assets/icons/IconNotificationFallbackImage'

type ReporterInfoBoxProps = {
	avatar?: string | null
	title?: string
	subTitle?: string | null
	contentType?: ContentType
	journalistIdx?: number
	isSubscribe?: boolean | null
	areaIdx?: number
	journalistPosition?: string
}

const ReporterInfoBox = ({
	avatar,
	title,
	subTitle,
	contentType,
	journalistIdx,
	isSubscribe,
	areaIdx,
	journalistPosition,
}: ReporterInfoBoxProps) => {
	return (
		<WrapperLink href={`/journalist/${journalistIdx}?${getAreaParams('HOME', areaIdx)}`}>
			{contentType && (
				<ContentBadgeBox
					position="absolute"
					top={{ xs: pxToRem(4), xl: pxToRem(6) }}
					left={{ xs: pxToRem(4), xl: pxToRem(6) }}
					fontSize={0}
				>
					<ContentBadge contentType={contentType} />
				</ContentBadgeBox>
			)}
			<Container>
				<ReporterAvatar src={avatar ?? undefined} alt="기자 이미지">
					<IconNotificationFallbackImage />
				</ReporterAvatar>
				<TextBox>
					<Title>
						{title ?? ''} {journalistPosition ?? ''}
					</Title>
					<UIFlex height={pxToRem(36)} justifyContent="center">
						<SubTitle>{subTitle ?? ''}</SubTitle>
					</UIFlex>
				</TextBox>
				<SubscribeButton
					isSubscribed={isSubscribe ?? false}
					target="journalist"
					idx={journalistIdx?.toString()}
				/>
			</Container>
		</WrapperLink>
	)
}

export default ReporterInfoBox

'use client'

import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import UIDivider from '@components/ui/UIDivider'
import { Divider, Grid } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import { ArticleItemProps } from '..'
import { createXNCardContent } from '../createContent'

const List2XN = ({
	list = [],
	isDark,
	areaIdx,
	packageIdx,
	isVideoNews = false,
}: {
	list: Array<ArticleItemProps>
	isDark?: boolean
	areaIdx?: number
	packageIdx?: string
	isVideoNews?: boolean
}) => {
	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: 'row',
			layoutGap: {
				xs: pxToRem(16),
				lg: pxToRem(20),
			},
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(162), lg: pxToRem(208), xl: pxToRem(280) },
			thumbHeight: { xs: pxToRem(92), lg: pxToRem(117), xl: pxToRem(158) },
			thumbRatio: '16/9',
			badgeInset: pxToRem(8),
		},
		infoConfig: {
			isDark,
			titleClamp: 3,
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						md: pxToRem(16),
						lg: pxToRem(17),
						xl: pxToRem(18),
					},
					lineHeight: {
						xs: pxToRem(22),
						lg: pxToRem(24),
						xl: pxToRem(26),
					},
				},
				'.info-additionalText': {
					fontSize: {
						xs: pxToRem(14),
					},
					lineHeight: {
						xs: pxToRem(18),
					},
				},
			},
		},
	}
	const drawItem = (item: ArticleItemProps, index: number) => {
		return (
			<>
				{index !== 0 && index % 2 !== 0 && (
					<UIDivider
						orientation="vertical"
						sx={{ display: { xs: 'none', md: 'block' } }}
						isDark={isDark}
					/>
				)}
				<Grid xs={12} md={6} key={`${item.articleIdx}-${item.articleTitle}`}>
					<ContentCard
						content={createXNCardContent({
							item,
							areaIdx,
							packageIdx,
							isVideoNews,
						})}
						layoutConfig={cardProps.layoutConfig}
						thumbnailConfig={cardProps.thumbnailConfig}
						infoConfig={cardProps.infoConfig}
					/>
				</Grid>
			</>
		)
	}
	return (
		<Grid
			container
			columnSpacing={{ xs: 0, md: pxToRem(24), lg: pxToRem(32) }}
			mt={{
				xs: pxToRem(-16),
				md: pxToRem(-32),
				xl: pxToRem(-36),
			}}
			sx={{
				'&>*': {
					marginTop: {
						xs: `${pxToRem(16)}!important`,
						md: `${pxToRem(32)}!important`,
						xl: `${pxToRem(36)}!important`,
					},
				},
			}}
		>
			{list.map(drawItem)}
			<Divider />
		</Grid>
	)
}

export default List2XN

import { ApiResultVoCommonAreaResponseVo } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { NewHomeReqModel } from '../types'
import { fetchAPI } from '@utils/http/apiRequest'

export type SelectionResponse = CommonResponse<ApiResultVoCommonAreaResponseVo>
export type SelectionResponseData = ApiResultVoCommonAreaResponseVo['data']

const path = '/restapi/v1/get/template/news-home/selection'
const queryKeyString = (props: NewHomeReqModel) => ['@template/news-home/selection', props] as const

const getQuery = async (
	props: NewHomeReqModel,
	cookieHeader?: string,
): Promise<ApiResultVoCommonAreaResponseVo> => {
	const { areaIdx, articleListType } = props
	const params = new URLSearchParams({
		...(areaIdx ? { areaIdx: String(areaIdx) } : {}),
		...(articleListType ? { articleListType: String(articleListType) } : {}),
	})
	return fetchAPI({ path, params, cookieHeader })
}

export const querySelection = (props: NewHomeReqModel, cookieHeader?: string) => ({
	queryKey: queryKeyString(props),
	queryFn: () => getQuery(props, cookieHeader),
})

export const prefetchSelection = (props: NewHomeReqModel, cookieHeader?: string) =>
	createPrefetchQuery(queryKeyString(props), () => getQuery(props, cookieHeader))

import { usePathname } from 'next/navigation'
import { SxProps } from '@mui/joy/styles/types'
import { UISlideItem } from '.'
import { SlideAnchor, SlideAnchorButton } from './styled'

interface UISlideAnchorProps {
	link?: boolean
	option: UISlideItem
	active?: string | number
	handleActive: (value: string | number) => void
	selected?: string | number
	sx: SxProps
}
const UISlideAnchor = ({
	link,
	option,
	active,
	sx,
	handleActive,
	selected,
}: UISlideAnchorProps) => {
	const pathname = usePathname()
	return link ? (
		<SlideAnchor
			href={option.href}
			role="tab"
			aria-selected={
				selected ? selected === option.value : option.href?.split('?')[0] === pathname
			}
			sx={sx}
		>
			{option.label}
		</SlideAnchor>
	) : (
		<SlideAnchorButton
			role="tab"
			aria-selected={active === option.value}
			onClick={() => handleActive(option.value)}
			sx={sx}
		>
			{option.label}
		</SlideAnchorButton>
	)
}
export default UISlideAnchor

'use client'

import { Box, styled } from '@mui/joy'
import { theme } from '@ui/style'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

interface OwnerState {
	border?: 'horizontal' | 'vertical' | 'both'
	isDark?: boolean
}

export const CSSGridListBorder = (border?: string, isDark?: boolean) => {
	if (border === 'horizontal') {
		return {
			marginLeft: pxToRem(-12),
			marginRight: pxToRem(-12),
			gap: `${pxToRem(12)} 0`,
			paddingLeft: pxToRem(12),
			paddingRight: pxToRem(12),
			[theme.breakpoints.up('lg')]: {
				marginLeft: pxToRem(-16),
				marginRight: pxToRem(-16),
				paddingLeft: pxToRem(16),
				paddingRight: pxToRem(16),
				gap: `${pxToRem(16)} 0`,
			},
			'&>*': {
				borderBottom: `1px solid ${isDark ? 'rgba(255,255,255,0.2)' : color.colGray3}`,
				paddingBottom: pxToRem(11),
				[theme.breakpoints.up('lg')]: {
					paddingBottom: pxToRem(15),
				},
			},
			'&>*:last-child': {
				borderBottom: '0',
				paddingBottom: '0',
			},
		}
	}

	if (border === 'vertical') {
		return {
			marginLeft: pxToRem(-12),
			marginRight: pxToRem(-12),
			gap: `${pxToRem(12)} 0`,
			[theme.breakpoints.up('lg')]: {
				marginLeft: pxToRem(-16),
				marginRight: pxToRem(-16),
				gap: `${pxToRem(16)} 0`,
			},
			'&>*': {
				paddingLeft: pxToRem(12),
				paddingRight: pxToRem(12),
				borderRight: `1px solid ${isDark ? 'rgba(255,255,255,0.2)' : color.colGray3}`,
				'&:last-child': {
					borderRight: '0',
				},
				[theme.breakpoints.up('lg')]: {
					paddingLeft: pxToRem(16),
					paddingRight: pxToRem(16),
				},
			},
		}
	}

	if (border === 'both') {
		return {
			gap: 0,
			marginBottom: pxToRem(-12),
			marginTop: pxToRem(-12),
			marginLeft: pxToRem(-12),
			marginRight: pxToRem(-12),
			[theme.breakpoints.up('lg')]: {
				marginLeft: pxToRem(-16),
				marginRight: pxToRem(-16),
				marginBottom: pxToRem(-16),
				marginTop: pxToRem(-16),
			},
			'&>*': {
				borderRight: `1px solid ${isDark ? 'rgba(255,255,255,0.2)' : color.colGray3}`,
				paddingLeft: pxToRem(12),
				paddingRight: pxToRem(12),
				paddingBottom: pxToRem(12),
				[theme.breakpoints.up('lg')]: {
					paddingLeft: pxToRem(16),
					paddingRight: pxToRem(16),
					paddingBottom: pxToRem(16),
				},
				'&>*': {
					borderTop: `1px solid ${isDark ? 'rgba(255,255,255,0.2)' : color.colGray3}`,
					paddingTop: pxToRem(11),
					[theme.breakpoints.up('lg')]: {
						paddingTop: pxToRem(15),
					},
				},
			},
		}
	}

	return null
}

export default styled(Box)(({ ownerState }: { ownerState: OwnerState }) => {
	const { border, isDark } = ownerState

	const styles = {
		display: 'grid',
		...CSSGridListBorder(border, isDark),
	}

	return styles
})

import { ApiResultVoCommonAreaResponseVo } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { NewHomeReqModel } from '../types'
import { fetchAPI } from '@utils/http/apiRequest'

export type NewsImportantResponse = CommonResponse<ApiResultVoCommonAreaResponseVo>
export type NewsImportantResponseData = ApiResultVoCommonAreaResponseVo['data']

const path = '/restapi/v1/get/template/news-home/news-important'
const queryKeyString = ['@template/news-home/news-important'] as const

const getQuery = async (
	props: NewHomeReqModel,
	cookieHeader?: string,
): Promise<ApiResultVoCommonAreaResponseVo> => {
	const { areaIdx } = props
	const params = new URLSearchParams({
		...(areaIdx ? { areaIdx: String(areaIdx) } : {}),
	})
	return fetchAPI({ path, params, cookieHeader })
}

export const queryNewsImportant = (props: NewHomeReqModel, cookieHeader?: string) => ({
	queryKey: [...queryKeyString],
	queryFn: () => getQuery(props, cookieHeader),
})

export const prefetchNewsImportant = (props: NewHomeReqModel, cookieHeader?: string) =>
	createPrefetchQuery(queryKeyString, () => getQuery(props, cookieHeader))

import { ApiResultVoShortFormNewsAreaResponseVo } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { NewHomeReqModel } from '../types'
import { fetchAPI } from '@utils/http/apiRequest'

export type ShortformNewsResponse = CommonResponse<ApiResultVoShortFormNewsAreaResponseVo>
export type ShortformNewsResponseData = ApiResultVoShortFormNewsAreaResponseVo['data']

const path = '/restapi/v1/get/template/news-home/shortform-news'
const queryKeyString = (props?: NewHomeReqModel) =>
	['@template/news-home/shortform-news', props] as const

const getQuery = async (
	props: NewHomeReqModel,
	cookieHeader?: string,
): Promise<ApiResultVoShortFormNewsAreaResponseVo> => {
	const { areaIdx, articleListType } = props
	const params = new URLSearchParams({
		...(areaIdx ? { areaIdx: String(areaIdx) } : {}),
		...(articleListType ? { articleListType: String(articleListType) } : {}),
	})
	return fetchAPI({ path, params, cookieHeader })
}

export const queryShortformNews = (props: NewHomeReqModel, cookieHeader?: string) => ({
	queryKey: queryKeyString(props),
	queryFn: () => getQuery(props, cookieHeader),
})

export const ShortformNewsMutationProps = (cookieHeader?: string) => ({
	mutationKey: queryKeyString(),
	mutationFn: (props: NewHomeReqModel) => getQuery(props, cookieHeader),
})

export const prefetchShortformNews = (props: NewHomeReqModel, cookieHeader?: string) =>
	createPrefetchQuery(queryKeyString(props), () => getQuery(props, cookieHeader))

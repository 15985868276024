'use client'

import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import { pxToRem } from '@ui/style/muiTheme'
import { getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import UIFlex from '@components/ui/UIFlex'
import CarouselList from '../CarouselList'
import { ArticleItemProps } from '..'
import { getArticleLink } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'

const ListOnBg = ({
	list = [],
	isDark,
	areaIdx,
}: {
	list: Array<ArticleItemProps>
	isDark?: boolean
	areaIdx?: number
}) => {
	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			layoutGap: pxToRem(16),
		},
		thumbnailConfig: {
			thumbRatio: '16/9',
			badgeInset: pxToRem(6),
			thumbHeight: {
				xl: pxToRem(158),
			},
		},
		infoConfig: {
			isDark,
			infoSx: {
				'.info-additionalText': {
					lineHeight: pxToRem(18),
				},
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						lg: pxToRem(17),
						xl: pxToRem(18),
					},
					lineHeight: {
						xs: pxToRem(22),
						lg: pxToRem(24),
						xl: pxToRem(27),
					},
				},
			},
		},
	}
	const drawItem = (item: ArticleItemProps, index: number) => {
		return (
			index < 4 && (
				<ContentCard
					key={`article-${item.articleIdx}-${item.publicationDate}`}
					content={{
						articleIdx: item?.articleIdx,
						title: item?.articleTitle ?? '',
						href: getArticleLink(item, 'HOME', areaIdx),
						src:
							(item?.vodInfo
								? item?.vodInfo.vodThumbnailImgUrl
								: item?.articleThumbnailImgUrl) ?? imgDefaultH,
						alt: '',
						additionalText: getRelativeTime(
							item?.publicationDate,
							item?.code === 'LIVE',
						),
						duration: item?.vodInfo?.playTime,
						bullet: item?.bulletType && item.bulletType.name,
						bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
						showLiveBadge: item?.code === 'LIVE',
						vodInfo: item?.vodInfo,
					}}
					layoutConfig={cardProps.layoutConfig}
					thumbnailConfig={cardProps.thumbnailConfig}
					infoConfig={cardProps.infoConfig}
				/>
			)
		)
	}
	return (
		<UIFlex>
			<CarouselList
				sx={(theme) => ({
					[theme.breakpoints.down('md')]: {
						'& .swiper-slide': {
							width: theme.pxToRem(224),
						},
					},
					'& .swiper-button-prev, & .swiper-button-next': {
						top: theme.pxToRem(39),
					},
				})}
			>
				{list?.map(drawItem)}
			</CarouselList>
		</UIFlex>
	)
}

export default ListOnBg

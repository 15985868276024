import { querySectionNews } from './SectionNews/index'
import { querySeriesCorner } from './SeriesCorner/index'
import { querySubscribeIssue } from './SubscribeIssue/index'
import { queryRecommendNews } from './RecommendNews/index'
import { queryIssueTopic } from './IssueTopic'
import { queryLive } from './Live'
import { queryNewsImportant } from './NewsImportant'
import { queryRankNews } from './RankNews'
import { queryShortformNews } from './ShortformNews'
import { queryVideoNews } from './VideoNews'
import { queryRecommendIssue } from './RecommendIssue'
import { queryTodaysJournalist } from './TodaysJournalist'
import { queryCorrectionRebuttal } from './CorrectionRebuttal'
import { queryBanner } from './Banner'
import { querySelection } from './Selection'

export const homeDivIdx = {
	live: 1001,
	newsImportant: 1002,
	issueTopic: 1003,
	videoNews: 1004,
	rankNews: 1005,
	shortsNews: 1006,
	recommendNews: 1007,
	subscribeIssue: 1008,
	journalist: 1009,
	series: 1010,
	sectionNews: 1011,
	correctionRebuttal: 1012,
	banner: 1013,
	recommendIssue: 1015,
}

export const getQueryNewHomeArea = (idx?: number) => {
	switch (idx) {
		case homeDivIdx.live:
			return queryLive
		case homeDivIdx.newsImportant:
			return queryNewsImportant
		case homeDivIdx.issueTopic:
			return queryIssueTopic
		case homeDivIdx.videoNews:
			return queryVideoNews
		case homeDivIdx.rankNews:
			return queryRankNews
		case homeDivIdx.shortsNews:
			return queryShortformNews
		case homeDivIdx.recommendNews:
			return queryRecommendNews
		case homeDivIdx.subscribeIssue:
			return querySubscribeIssue
		case homeDivIdx.journalist:
			return queryTodaysJournalist
		case homeDivIdx.series:
			return querySeriesCorner
		case homeDivIdx.sectionNews:
			return querySectionNews
		case homeDivIdx.correctionRebuttal:
			return queryCorrectionRebuttal
		case homeDivIdx.banner:
			return queryBanner
		case homeDivIdx.recommendIssue:
			return queryRecommendIssue
		default:
			return querySelection
	}
}

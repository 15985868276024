import { BannerVo, CommonAreaResponseVo, HomeAreaSequenceResponseVo } from '@schemas/non-auth'
import Section from '@components/common/Section/Section'
import ListBanner from '@components/list/ListBanner'
import UIContainer from '@components/ui/UIContainer'
import SectionTitle from '@components/common/Section/SectionTitle'

export type BannerSectionData = HomeAreaSequenceResponseVo & CommonAreaResponseVo
interface SectionProps {
	data: BannerSectionData
}

const BannerSection = ({ data }: SectionProps) => {
	const { areaItemList, bgMoImgUrl, bgTbImgUrl, bgPcImgUrl, isStaticBackgroundColor } = data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }
	return (
		<Section
			bgColor={isStaticBackgroundColor}
			{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
		>
			<UIContainer>
				{data.areaName && data.areaName !== '' && (
					<SectionTitle
						isDark={!!src && !isStaticBackgroundColor}
						showLine={!src && !isStaticBackgroundColor}
					>
						{data.areaName}
					</SectionTitle>
				)}
				<ListBanner
					list={areaItemList as unknown as Array<BannerVo>}
					areaIdx={data.areaIdx}
				/>
			</UIContainer>
		</Section>
	)
}
export default BannerSection

export const IconRelatedBracket = ({ className, color = '#242424' }: SVGComponentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			className={className}
		>
			<path d="M1 0V8H9" stroke={color} strokeWidth="1.5" />
		</svg>
	)
}

'use client'

import { Box, Grid } from '@mui/joy'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import { pxToRem } from '@ui/style/muiTheme'
import UIDivider from '@components/ui/UIDivider'
import LargeContentCard, { LargeContentCardProps } from '@components/content/LargeContentCard'
import { theme } from '@ui/style'
import CSSgridList from '@components/list/CSSgridList'
import { CSSGridListBorder } from '@components/list/CSSgridList/CSSgridListRoot'
import getBulletColor from '@utils/getBulletColor'
import { insetLayoutStyle } from '@components/content/LargeContentCard/ContentInfoWrapper'
import { ArticleItemProps } from '..'
import { getArticleLink } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'
import { getRelativeTime } from '@utils/date'
import color from '@ui/style/color.theme'

const ListMDimage3 = ({
	list = [],
	isDark,
	areaIdx,
	packageIdx,
	isVideoNews = false,
}: {
	list: Array<ArticleItemProps>
	isDark?: boolean
	areaIdx?: number
	packageIdx?: string
	isVideoNews?: boolean
}) => {
	const [article1st, ...articleList] = list

	const largeCardProps: Omit<LargeContentCardProps, 'content'> = {
		layoutConfig: {
			layoutGap: {
				xs: pxToRem(12),
				md: pxToRem(16),
			},
			layoutSx: {
				pb: { xs: pxToRem(12), md: 0 },
				[theme.breakpoints.up('xl')]: {
					'.largeContentCard-wrapper': {
						...insetLayoutStyle,
					},
				},
				'.largeContentCard-extraContent': {
					display: {
						xs: 'none',
						xl: 'block',
					},
					position: 'absolute',
					right: pxToRem(6),
					bottom: pxToRem(6),
					padding: `${pxToRem(4)} ${pxToRem(8)}`,
					background: 'rgba(0,0,0,0.5)',
					borderRadius: '100px',
					fontWeight: 500,
					color: color.colWhite,
					lineHeight: pxToRem(14),
					fontSize: pxToRem(13),
				},
			},
		},
		thumbnailConfig: {
			badgeInset: pxToRem(8),
			thumbRatio: '16/9',
			thumbWidth: {
				xs: '100%',
				md: pxToRem(286),
				lg: pxToRem(368),
				xl: pxToRem(592),
			},
			thumbHeight: {
				xs: 'auto',
				md: pxToRem(162),
				lg: pxToRem(208),
				xl: pxToRem(376),
			},
			thumbSx: {
				'.thumb-duration': {
					display: {
						xl: 'none',
					},
				},
			},
		},
		infoConfig: {
			isDark,
			infoSx: {
				[theme.breakpoints.down('md')]: {
					'.info-title': {
						fontSize: pxToRem(20),
						lineHeight: pxToRem(28),
					},
					'.info-additionalBox': {
						marginTop: pxToRem(8),
					},
				},
				[theme.breakpoints.up('md')]: {
					'.info-title': {
						fontSize: pxToRem(20),
						lineHeight: pxToRem(24),
					},
					'.info-additionalBox': {
						marginTop: 'auto',
					},
				},
				[theme.breakpoints.only('lg')]: {
					'.info-title': {
						WebkitLineClamp: 1,
					},
				},
				[theme.breakpoints.up('xl')]: {
					'.info-title': {
						fontSize: pxToRem(28),
						lineHeight: pxToRem(38),
						width: pxToRem(400),
					},
					'.info-additionalBox': {
						marginBottom: pxToRem(12),
					},
				},
			},
		},
		// isInsetLayout: true,
	}

	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: 'row-reverse',
			layoutGap: pxToRem(16),
			layoutSx: {
				alignItems: 'flex-start',
			},
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(120), md: pxToRem(96), lg: pxToRem(96), xl: pxToRem(120) },
			thumbHeight: { xs: pxToRem(68), md: pxToRem(54), lg: pxToRem(54), xl: pxToRem(68) },
			badgeInset: pxToRem(6),
			thumbSx: {
				'.thumb-videoViewIcon': {
					top: {
						xs: pxToRem(6),
						md: pxToRem(4),
						xl: pxToRem(6),
					},
				},
				'.thumb-duration': {
					bottom: {
						xs: pxToRem(6),
						md: pxToRem(4),
						xl: pxToRem(6),
					},
				},
			},
		},
		infoConfig: {
			isDark,
			infoSx: {
				'.info-title': {
					WebkitLineClamp: { xs: 2, md: 3, lg: 2 },
					height: {
						md: pxToRem(66),
						lg: pxToRem(44),
					},
					fontSize: {
						xs: pxToRem(16),
					},
					lineHeight: {
						xs: pxToRem(22),
					},
				},
			},
		},
	}
	const createCardContent = (item: ArticleItemProps) => ({
		articleIdx: item?.articleIdx,
		src:
			(item?.vodInfo ? item?.vodInfo.vodThumbnailImgUrl : item?.articleThumbnailImgUrl) ??
			undefined,
		href: `${getArticleLink(item, 'HOME', areaIdx)}${
			isVideoNews ? `&code=PROGRAM&idx=${packageIdx}` : ''
		}`,
		alt: '',
		title: item?.articleTitle ?? undefined,
		additionalText: !isVideoNews
			? getRelativeTime(item?.publicationDate ?? '')
			: item?.isVideoView
			? undefined
			: item?.journalistName ?? undefined,
		duration: item?.vodInfo?.playTime,
		bullet: item?.bulletType && item.bulletType.name,
		bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
		showLiveBadge: item?.code === 'LIVE',
		vodInfo: item?.vodInfo,
	})
	const drawArticleItem = (item: ArticleItemProps) => {
		return (
			<Box className="article-item" key={`ListMDimage3-${item.code}-${item?.articleIdx}`}>
				<ContentCard
					content={{
						...createCardContent(item),
						duration: item?.vodInfo?.playTime,
					}}
					layoutConfig={cardProps.layoutConfig}
					thumbnailConfig={cardProps.thumbnailConfig}
					infoConfig={cardProps.infoConfig}
				/>
			</Box>
		)
	}
	return (
		<Grid
			container
			columnSpacing={{ xs: 0, md: pxToRem(24), lg: pxToRem(32) }}
			rowSpacing={{ xs: pxToRem(24), md: 0 }}
			className="list-MDimage3"
		>
			<Grid xs={12} md={5} xl={6}>
				<LargeContentCard
					content={{
						...createCardContent(article1st),
						duration: article1st?.vodInfo?.playTime,
					}}
					layoutConfig={largeCardProps.layoutConfig}
					thumbnailConfig={largeCardProps.thumbnailConfig}
					infoConfig={largeCardProps.infoConfig}
					isInsetLayout={largeCardProps.isInsetLayout}
					extraContent={article1st?.vodInfo?.playTime}
				/>
			</Grid>
			<UIDivider orientation={{ xs: 'horizontal', md: 'vertical' }} isDark={isDark} />
			<Grid xs={12} md={7} xl={6}>
				<CSSgridList
					gridTemplateRows={{ md: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }}
					gridTemplateColumns={{ xs: '1fr', md: 'repeat(2, 1fr)' }}
					gridAutoFlow={{ xs: 'row', md: 'column' }}
					isDark={isDark}
					sx={{
						'& .article-item:nth-child(8) ~ .article-item': {
							display: 'none',
						},
						[theme.breakpoints.down('md')]: {
							...CSSGridListBorder('horizontal', isDark),
							'& .article-item:nth-child(4)': {
								borderBottom: 0,
								paddingBottom: 0,
							},
							'& .article-item:nth-child(4) ~ .article-item': {
								display: 'none',
							},
							' >*': {
								paddingBottom: pxToRem(11),
							},
						},
						[theme.breakpoints.down('xl')]: {
							'& .article-item:nth-child(6) ~ .article-item': {
								display: 'none',
							},
						},
						[theme.breakpoints.up('md')]: {
							...CSSGridListBorder('both', isDark),
						},
					}}
				>
					{articleList?.map(drawArticleItem)}
				</CSSgridList>
			</Grid>
		</Grid>
	)
}

export default ListMDimage3

import { ApiResultVoRecommendIssueAreaResponseVo } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { NewHomeReqModel } from '../types'
import { fetchAPI } from '@utils/http/apiRequest'

export type RecommendIssueResponse = CommonResponse<ApiResultVoRecommendIssueAreaResponseVo>
export type RecommendIssueResponseData = ApiResultVoRecommendIssueAreaResponseVo['data']

const path = '/restapi/v1/get/template/news-home/recommend-issue'
const queryKeyString = ['@template/news-home/recommend-issue'] as const

const getQuery = async (
	props: NewHomeReqModel,
	cookieHeader?: string,
): Promise<ApiResultVoRecommendIssueAreaResponseVo> => {
	const { id, areaIdx, articleListType } = props
	const params = new URLSearchParams({
		...(areaIdx ? { areaIdx: String(areaIdx) } : {}),
		...(articleListType ? { articleListType: String(articleListType) } : {}),
		...(id ? { id: String(id) } : {}),
	})
	return fetchAPI({ path, params, cookieHeader })
}

export const queryRecommendIssue = (props: NewHomeReqModel, cookieHeader?: string) => ({
	queryKey: [...queryKeyString],
	queryFn: () => getQuery(props, cookieHeader),
})

export const prefetchRecommendIssue = (props: NewHomeReqModel, cookieHeader?: string) =>
	createPrefetchQuery(queryKeyString, () => getQuery(props, cookieHeader))

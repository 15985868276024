'use client'

import { CorrectionOrRebuttalAreaResponseVo, HomeAreaSequenceResponseVo } from '@schemas/non-auth'
import { IconGoIcons } from 'src/assets/icons/IconGoIcons'
import Section from '@components/common/Section/Section'
import UIContainer from '@components/ui/UIContainer'
import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import { getRelativeTime } from '@utils/date'
import color from '@ui/style/color.theme'
import {
	CorrectionAnchor,
	CorrectionBox,
	CorrectionContent,
	CorrectionDate,
	CorrectionItem,
	CorrectionList,
	CorrectionMoreAnchor,
	CorrectionText,
	CorrectionTitle,
} from './styled'
import { getArticleLink } from '@utils/getLink'
import UIText from '@components/ui/UIText'

export type CorrectionRebuttalSectionData = HomeAreaSequenceResponseVo &
	CorrectionOrRebuttalAreaResponseVo
interface SectionProps {
	data: CorrectionRebuttalSectionData
}
const CorrectionRebuttalSection = ({ data }: SectionProps) => {
	const componentLink = { component: UILink, href: '/correctionRebuttal' }
	const {
		correctionOrRebuttalArticlelist,
		bgMoImgUrl,
		bgTbImgUrl,
		bgPcImgUrl,
		isStaticBackgroundColor,
	} = data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }

	const textColor = !!src && !isStaticBackgroundColor ? color.colWhite : color.colBlack
	return (
		(correctionOrRebuttalArticlelist?.length ?? 0) > 0 && (
			<Section
				bgColor={isStaticBackgroundColor}
				{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
			>
				<UIContainer>
					<CorrectionBox>
						<UIFlex direction="row" align="center" flexShrink={0}>
							<CorrectionTitle weight={700} {...componentLink} color={textColor}>
								{data.areaName}
							</CorrectionTitle>
							<CorrectionMoreAnchor
								href={componentLink.href}
								sx={{
									'.goIcon': {
										'&-md': {
											display: {
												xs: 'none',
												md: 'block',
											},
										},
										'&-sm': {
											display: {
												md: 'none',
											},
										},
									},
								}}
							>
								<UIText readonly>더 보기</UIText>
								<IconGoIcons
									className="goIcon-md"
									size="md"
									isDark={!!src && !isStaticBackgroundColor}
								/>
								<IconGoIcons
									className="goIcon-sm"
									size="sm"
									isDark={!!src && !isStaticBackgroundColor}
								/>
							</CorrectionMoreAnchor>
						</UIFlex>
						<CorrectionContent flexGrow={1} flexShrink={1}>
							<CorrectionList>
								{correctionOrRebuttalArticlelist.map((item) => (
									<CorrectionItem
										key={`article-${item.articleIdx}-${item.publicationDate}`}
									>
										<CorrectionAnchor
											href={getArticleLink(item, 'HOME', data.areaIdx)}
										>
											<CorrectionText lineClamp={1} color={textColor}>
												{item.articleTitle}
											</CorrectionText>
											<CorrectionDate color={textColor}>
												{getRelativeTime(item.publicationDate)}
											</CorrectionDate>
										</CorrectionAnchor>
									</CorrectionItem>
								))}
							</CorrectionList>
						</CorrectionContent>
					</CorrectionBox>
				</UIContainer>
			</Section>
		)
	)
}
export default CorrectionRebuttalSection

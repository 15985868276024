import { useEffect, useState } from 'react'
import {
	SectionAreaItem,
	SectionNewsAreaResponseVo,
	HomeAreaSequenceResponseVo,
} from '@schemas/non-auth'
import { pxToRem } from '@ui/style/muiTheme'
import { SeeAllButton } from '@components/button/SeeAllButton'
import Section from '@components/common/Section/Section'
import SectionTitle from '@components/common/Section/SectionTitle'
import ListNewsByField from '@components/list/ListNewsByField'
import UIContainer from '@components/ui/UIContainer'
import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UISlideTab from '@components/ui/UITab'
import { Box } from '@mui/joy'

export type SectorNewsSectionData = HomeAreaSequenceResponseVo & SectionNewsAreaResponseVo
interface SectionProps {
	data: SectorNewsSectionData
}

const SectorNewsSection = ({ data }: SectionProps) => {
	const {
		sectionAreaItemList: sectionList,
		bgMoImgUrl,
		bgTbImgUrl,
		bgPcImgUrl,
		isStaticBackgroundColor,
	} = data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }
	const [current, setCurrent] = useState<number>(0)
	const [item, setItem] = useState<SectionAreaItem>()
	useEffect(() => {
		setItem(sectionList?.[current])
	}, [sectionList, current])
	return (
		(sectionList?.length ?? 0) > 0 && (
			<Section
				bgColor={isStaticBackgroundColor}
				{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
			>
				<UIContainer>
					{data.areaName && data.areaName !== '' && (
						<SectionTitle
							isDark={!!src && !isStaticBackgroundColor}
							showLine={!src && !isStaticBackgroundColor}
						>
							{data.areaName}
						</SectionTitle>
					)}
					<UIFlex
						sx={{
							'&>*:not(:first-child)': {
								marginTop: pxToRem(24),
							},
						}}
					>
						<Box mx={{ xs: '-20px', md: 0 }}>
							<UISlideTab
								button
								options={sectionList?.map((option, index) => ({
									label: option.sectionName,
									value: index,
								}))}
								current={current}
								setCurrent={(value) => setCurrent(value as number)}
							/>
						</Box>
						<UIFlex
							sx={{
								'&>*:not(:first-child)': {
									marginTop: pxToRem(32),
								},
							}}
						>
							<ListNewsByField
								list={item?.sectionArticlelist?.slice(0, 9)}
								isDark={!!src && !isStaticBackgroundColor}
								areaIdx={data.areaIdx}
								key={item?.sectionIdx}
							/>
							<UIFlex align="center" justify="center">
								<UILink
									href={`/sections/${item?.sectionIdx}`}
									key={`more-${item?.sectionIdx}`}
								>
									<SeeAllButton title={item?.sectionName} tabIndex={-1} />
								</UILink>
							</UIFlex>
						</UIFlex>
					</UIFlex>
				</UIContainer>
			</Section>
		)
	)
}
export default SectorNewsSection

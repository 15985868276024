'use client'

import { Grid, Box } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import Carousel from '@components/common/Carousel/Carousel'
import { JournalistResponseVo } from '@schemas/non-auth'
import ReporterInfoBox from './ReporterInfoBox'

const ListTodayReporter = ({
	list = [],
	areaIdx,
}: {
	list?: Array<JournalistResponseVo>
	areaIdx?: number
}) => {
	const carouselConfig = {
		slidesPerView: 'auto' as const,
		spaceBetween: 16,
		slidesOffsetBefore: 20,
		slidesOffsetAfter: 20,
		breakpoints: {
			768: {
				slidesPerView: 4,
				spaceBetween: 16,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
			},
			1024: {
				slidesPerView: 5,
				spaceBetween: 16,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
			},
			1280: {
				slidesPerView: 6,
				spaceBetween: 20,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
			},
		},
	}

	const drawJournalist = (item: JournalistResponseVo) => (
		<Grid
			key={`journalist-${item.journalistIdx}-${item.isSubscribe}-${areaIdx}`}
			xs={12}
			md={4}
			lg={3}
			xl={2}
		>
			<ReporterInfoBox
				journalistIdx={item.journalistIdx}
				avatar={item?.journalistProfileImgUrl}
				title={`${item?.journalistName} ${item?.journalistPosition ?? '기자'}`}
				subTitle={item?.journalistIntroduction}
				contentType={item?.badgeCode?.code}
				isSubscribe={item.isSubscribe}
				areaIdx={areaIdx}
			/>
		</Grid>
	)
	return (
		<Box
			mx={{ xs: pxToRem(-20), md: 0 }}
			sx={(theme) => ({
				'& .swiper:not(.swiper-initialized)': {
					[theme.breakpoints.down('md')]: {
						'& .swiper-wrapper': {
							paddingLeft: theme.pxToRem(20),
							paddingRight: theme.pxToRem(20),
							'&> *:not(:first-child)': { marginLeft: pxToRem(16) },
						},
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(16 * 2)}) / 2.325)`,
						},
					},
					[theme.breakpoints.up('md')]: {
						'& .swiper-wrapper': {
							'&> *:not(:first-child)': { marginLeft: pxToRem(16) },
						},
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(16 * 3)}) / 4)`,
						},
					},
					[theme.breakpoints.up('lg')]: {
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(20 * 4)}) / 5)`,
						},
					},
					[theme.breakpoints.up('xl')]: {
						'& .swiper-wrapper': {
							'&> *:not(:first-child)': { marginLeft: pxToRem(20) },
						},
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(20 * 5)}) / 6)`,
						},
					},
				},
				'.swiper-slide': {
					[theme.breakpoints.down('md')]: {
						width: pxToRem(160),
					},
				},
			})}
		>
			<Carousel {...carouselConfig}>{list?.map(drawJournalist)}</Carousel>
		</Box>
	)
}

export default ListTodayReporter

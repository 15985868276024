'use client'

import { Box, Grid } from '@mui/joy'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import { pxToRem } from '@ui/style/muiTheme'
import UIDivider from '@components/ui/UIDivider'
import { theme } from '@ui/style'
import LargeContentCard from '@components/content/LargeContentCard'
import UIFlex from '@components/ui/UIFlex'
import { getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import RelatedItem from './RealatedItem'
import { ArticleItemProps } from '..'
import { getArticleLink } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'
import { Fragment } from 'react'
import UIText from '@components/ui/UIText'
import ContentBullet from '@components/content/ContentCard/ContentInfo/ContentBullet'

const ListLgimageRelated = ({
	list = [],
	isDark,
	areaIdx,
}: {
	list: Array<ArticleItemProps>
	isDark?: boolean
	areaIdx?: number
}) => {
	const [article1st, sub1st, sub2st, article2st, ...otherList] = list

	const MainCardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: {
				xs: 'column',
				md: 'row',
			},
			layoutGap: {
				xs: pxToRem(16),
				md: pxToRem(24),
				xl: pxToRem(32),
			},
			layoutSx: {
				margin: { xs: `0 -${pxToRem(20)}`, md: 0 },
				pb: { xs: pxToRem(20), md: 0 },
				'.largeContentCard-wrapper': {
					padding: {
						lg: `${pxToRem(4)} 0`,
						xl: `${pxToRem(4)} ${pxToRem(48)} ${pxToRem(8)} 0`,
					},
				},
			},
			linkSepareted: true,
		},
		thumbnailConfig: {
			badgeInset: { xs: pxToRem(8), lg: pxToRem(20) },
			thumbRatio: '393/221',
			thumbWidth: { xs: '100%', md: pxToRem(348), lg: pxToRem(452), xl: pxToRem(592) },
			thumbSx: {
				'.thumb-liveBadge': {
					[theme.breakpoints.up('lg')]: {
						marginTop: pxToRem(-4),
					},
				},
				'.MuiAspectRatio': {
					[theme.breakpoints.up('md')]: {
						'&-content': {
							paddingBottom: pxToRem(196),
						},
					},
					[theme.breakpoints.up('lg')]: {
						'&-content': {
							paddingBottom: pxToRem(252),
						},
					},
					[theme.breakpoints.up('xl')]: {
						'&-content': {
							paddingBottom: pxToRem(333),
						},
					},
				},
			},
		},
		infoConfig: {
			isDark,
			titleLevel: 'DisplayXL',
			bodyClamp: 2,
			infoGap: {
				xs: pxToRem(12),
				md: pxToRem(8),
				lg: pxToRem(12),
			},
			infoSx: {
				paddingX: { xs: pxToRem(20), md: 0 },
				'.info-title': {
					fontSize: {
						xs: pxToRem(26),
						md: pxToRem(26),
						lg: pxToRem(28),
						xl: pxToRem(32),
					},
					lineHeight: {
						xs: pxToRem(34),
						lg: pxToRem(36),
						xl: pxToRem(42),
					},
				},
				'.info-body': {
					fontSize: {
						xs: pxToRem(14),
						lg: pxToRem(15),
					},
					lineHeight: {
						xs: pxToRem(20),
						lg: pxToRem(22),
					},
					WebkitLineClamp: {
						xl: 3,
					},
				},
			},
		},
	}

	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: { xs: 'row-reverse', md: 'column' },
			layoutGap: pxToRem(16),
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(120), md: pxToRem(224), lg: pxToRem(208), xl: pxToRem(280) },
			thumbHeight: { xs: pxToRem(68), md: pxToRem(126), lg: pxToRem(117), xl: pxToRem(158) },
		},
		infoConfig: {
			isDark,
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						lg: pxToRem(17),
						xl: pxToRem(18),
					},
					lineHeight: {
						xs: pxToRem(22),
						lg: pxToRem(24),
						xl: pxToRem(26),
					},
				},
			},
		},
	}

	const createCardContent = (item: ArticleItemProps, isLargeCard = false) => ({
		articleIdx: item?.articleIdx,
		src:
			(item?.vodInfo ? item?.vodInfo.vodThumbnailImgUrl : item?.articleThumbnailImgUrl) ??
			imgDefaultH,
		href: getArticleLink(item, 'HOME', areaIdx),
		alt: '',
		title: item?.articleTitle,
		body: isLargeCard ? article1st.articleInnerTextContent ?? '' : undefined,
		additionalText: getRelativeTime(item?.publicationDate ?? '', item?.code === 'LIVE'),
		duration: !isLargeCard ? item?.vodInfo?.playTime : undefined,
		bullet: item?.bulletType && item.bulletType.name,
		bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
		showLiveBadge: item?.code === 'LIVE',
		vodInfo: item?.vodInfo,
	})
	const drawOtherItem = (item: ArticleItemProps, index: number) => (
		<Fragment key={`article-${item.articleIdx}`}>
			<UIDivider
				orientation={{ xs: 'horizontal', md: 'vertical' }}
				isDark={isDark}
				sx={{
					display: {
						md: (index + 1) % 3 !== 0 ? 'block' : 'none',
						lg: (index + 1) % 4 !== 0 ? 'block' : 'none',
					},
				}}
			/>
			<Grid xs={12} md={4} lg={3}>
				<ContentCard
					content={createCardContent(item)}
					layoutConfig={cardProps.layoutConfig}
					thumbnailConfig={cardProps.thumbnailConfig}
					infoConfig={cardProps.infoConfig}
				/>
			</Grid>
		</Fragment>
	)
	return (
		<Box overflow={{ sx: 'visible', md: 'hidden' }} className="list-LGimageRelated">
			<Grid
				container
				columnSpacing={{ xs: 0, md: pxToRem(24), lg: pxToRem(32) }}
				sx={{
					'.MuiGrid-root:nth-of-type(n + 8)': {
						display: { xs: 'block', md: 'none', lg: 'block' },
					},
					'&>*:not(:first-child)': {
						marginTop: {
							xs: pxToRem(12),
							md: pxToRem(32),
						},
					},
				}}
			>
				<Grid xs={12}>
					<LargeContentCard
						content={createCardContent(article1st, true)}
						layoutConfig={MainCardProps.layoutConfig}
						thumbnailConfig={MainCardProps.thumbnailConfig}
						infoConfig={MainCardProps.infoConfig}
						extraContent={
							<UIFlex
								mt={{ xs: pxToRem(24), md: 'auto' }}
								px={{ xs: pxToRem(20), md: 0 }}
								sx={{
									'&>*:not(:first-child)': {
										marginTop: {
											xs: pxToRem(4),
											xl: pxToRem(8),
										},
									},
								}}
							>
								{sub1st && (
									<RelatedItem
										href={getArticleLink(sub1st, 'HOME', areaIdx) as string}
										isDark={isDark}
									>
										<ContentBullet bullet={sub1st.bulletType.name} />
										{sub1st.articleTitle}
									</RelatedItem>
								)}
								{sub2st && (
									<RelatedItem
										href={getArticleLink(sub2st, 'HOME', areaIdx) as string}
										isDark={isDark}
									>
										<ContentBullet bullet={sub2st.bulletType.name} />
										{sub2st.articleTitle}
									</RelatedItem>
								)}
							</UIFlex>
						}
					/>
				</Grid>
				{article2st && (
					<>
						<UIDivider
							orientation="horizontal"
							isDark={isDark}
							sx={{
								marginTop: {
									md: `${pxToRem(16)}!important`,
								},
								marginBottom: {
									md: `${pxToRem(-16)}!important`,
									xl: `${pxToRem(-18)}!important`,
								},
							}}
						/>
						<Grid xs={12} md={4} lg={3}>
							<ContentCard
								content={createCardContent(article2st)}
								layoutConfig={cardProps.layoutConfig}
								thumbnailConfig={cardProps.thumbnailConfig}
								infoConfig={cardProps.infoConfig}
							/>
						</Grid>
					</>
				)}
				{otherList?.map(drawOtherItem)}
			</Grid>
		</Box>
	)
}

export default ListLgimageRelated

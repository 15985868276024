import { useState } from 'react'
import { RankNewsAreaResponseVo, HomeAreaSequenceResponseVo } from '@schemas/non-auth'
import { useRankNews } from '@services/api/NewsHome/RankNews'
import { pxToRem } from '@ui/style/muiTheme'
import Section from '@components/common/Section/Section'
import SectionTitle from '@components/common/Section/SectionTitle'
import ListRankingNews from '@components/list/ListRankingNews'
import UIContainer from '@components/ui/UIContainer'
import UIFlex from '@components/ui/UIFlex'
import UISlideTab from '@components/ui/UITab'
import { Box } from '@mui/joy'
import UILink from '@components/ui/UILink'
import { getAreaParams } from '@utils/getLink'
import { SeeAllButton } from '@components/button/SeeAllButton'

export type RankNewsSectionData = HomeAreaSequenceResponseVo & RankNewsAreaResponseVo
interface SectionProps {
	data: RankNewsSectionData
}

const RankNewsSection = ({ data }: SectionProps) => {
	const [current, setCurrent] = useState<number>(0)
	const {
		rankNewsArticlelist: viewArticleList,
		bgMoImgUrl,
		bgTbImgUrl,
		bgPcImgUrl,
		isStaticBackgroundColor,
	} = data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }
	const commentNews = useRankNews({
		areaIdx: data.areaIdx,
		rankType: 'COMMENT',
	})
	const commentArticleList = commentNews?.data?.rankNewsArticlelist
	const isViewList = (viewArticleList?.length ?? 0) > 0
	const isCommentList = (commentArticleList?.length ?? 0) > 0
	return (
		(isViewList || isCommentList) && (
			<Section
				bgColor={isStaticBackgroundColor}
				{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
			>
				<UIContainer>
					{data.areaName && data.areaName !== '' && (
						<SectionTitle
							isDark={!!src && !isStaticBackgroundColor}
							showLine={!src && !isStaticBackgroundColor}
						>
							{data.areaName}
						</SectionTitle>
					)}
					<UIFlex
						sx={{
							'&>*:not(:first-child)': {
								marginTop: pxToRem(24),
							},
						}}
					>
						<Box mx={{ xs: '-20px', md: 0 }}>
							<UISlideTab
								button
								options={[
									...(isViewList ? [{ label: '많이 본', value: 0 }] : []),
									...(isCommentList ? [{ label: '댓글 많은', value: 1 }] : []),
								]}
								current={current}
								setCurrent={(value) => setCurrent(value as number)}
							/>
						</Box>
						<UIFlex
							sx={{
								'&>*:not(:first-child)': {
									marginTop: pxToRem(32),
								},
							}}
						>
							<ListRankingNews
								key={`listtype-${current === 0 ? 'view' : 'comment'}`}
								list={current === 0 ? viewArticleList : commentArticleList}
								isDark={!!src && !isStaticBackgroundColor}
								areaIdx={data.areaIdx}
							/>
							<UIFlex align="center" justify="center">
								<UILink
									href={`/rankNews${
										current === 0 ? '' : '/comment'
									}?${getAreaParams('HOME', data.areaIdx)}`}
									key={`more-${data?.areaIdx}`}
								>
									<SeeAllButton
										title={current === 0 ? '많이 본 기사' : '댓글 많은 기사'}
										tabIndex={-1}
									/>
								</UILink>
							</UIFlex>
						</UIFlex>
					</UIFlex>
				</UIContainer>
			</Section>
		)
	)
}
export default RankNewsSection

import { getArticleLink } from '@utils/getLink'
import { ArticleItemProps } from '.'
import { getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import { imgDefaultH } from 'src/shared/variables'

export const createXNCardContent = ({
	item,
	areaIdx,
	isVideoNews = false,
	packageIdx,
}: {
	item: ArticleItemProps
	areaIdx?: number
	isVideoNews?: boolean
	packageIdx?: string
}) => {
	return {
		articleIdx: item?.articleIdx,
		title: item?.articleTitle ?? '',
		href: `${getArticleLink(item, 'HOME', areaIdx)}${
			isVideoNews ? `&code=PROGRAM&idx=${packageIdx}` : ''
		}`,
		src:
			(item?.vodInfo ? item?.vodInfo.vodThumbnailImgUrl : item?.articleThumbnailImgUrl) ??
			imgDefaultH,
		alt: '',
		additionalText: !isVideoNews
			? getRelativeTime(item?.publicationDate ?? '', item.code === 'LIVE')
			: item?.isVideoView
			? undefined
			: item?.journalistName ?? undefined,
		duration: item?.vodInfo?.playTime,
		bullet: item?.bulletType && item.bulletType.name,
		bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
		showVideoViewIcon: (item?.code !== 'LIVE' && item?.isVideoView) ?? false,
		showLiveBadge: item?.code === 'LIVE',
		vodInfo: item?.vodInfo,
	}
}

'use client'

import { ArticleListResponseVo } from '@schemas/non-auth'
import { pxToRem } from '@ui/style/muiTheme'
import dayjs from 'dayjs'
import { DATE_FORMAT, getRelativeTime } from '@utils/date'
import CarouselList from '../CarouselList'
import HorizontalTimelineItem from './HorizontalTimeLineItem'
import { getArticleLink } from '@utils/getLink'
import { theme } from '@ui/style'
import { Box } from '@mui/joy'
import Carousel from '@components/common/Carousel/Carousel'

const ListTimeline = ({
	list = [],
	isDark,
	areaIdx,
}: {
	list: Array<ArticleListResponseVo>
	isDark?: boolean
	areaIdx?: number
}) => {
	const computeSortItem = (a: ArticleListResponseVo, b: ArticleListResponseVo) =>
		Number(dayjs(b.publicationDate).format(DATE_FORMAT.FULL_AS_NUMBER)) -
		Number(dayjs(a.publicationDate).format(DATE_FORMAT.FULL_AS_NUMBER))
	const drawTimelineItem = (item: ArticleListResponseVo) => (
		<HorizontalTimelineItem
			key={`article-${item.articleIdx}-${areaIdx}`}
			time={getRelativeTime(item?.publicationDate ?? '')}
			title={item.articleTitle ?? ''}
			href={getArticleLink(item, 'HOME', areaIdx) ?? ''}
			isDark={isDark}
		/>
	)
	const sortlist = list.sort(computeSortItem)
	const carouselConfig = {
		slidesPerView: 'auto' as const,
		navigation: false,
		slidesOffsetBefore: 20,
		slidesOffsetAfter: 20,
		breakpoints: {
			768: {
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
			},
		},
	}
	return (
		<Box
			mx={{ xs: pxToRem(-20), md: 0 }}
			mt={{
				xs: pxToRem(132),
				md: pxToRem(128),
				lg: pxToRem(123),
				xl: pxToRem(164),
			}}
			sx={{
				'& .swiper:not(.swiper-initialized)': {
					[theme.breakpoints.down('md')]: {
						'& .swiper-wrapper': {
							paddingLeft: pxToRem(20),
							paddingRight: pxToRem(20),
						},
					},
				},
				'& .swiper-slide:nth-child(3) ~ .swiper-slide': {
					[theme.breakpoints.only('md')]: {
						display: 'none',
					},
				},
				'& .swiper-slide': {
					width: {
						xs: pxToRem(232),
						md: pxToRem(240),
						lg: pxToRem(232),
						xl: pxToRem(312),
					},
					'&:nth-child(4n)': {
						width: {
							xl: pxToRem(280),
						},
					},
				},
			}}
		>
			<Carousel {...carouselConfig}>{sortlist.map(drawTimelineItem)}</Carousel>
		</Box>
	)
}

export default ListTimeline

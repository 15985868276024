import { CommonAreaResponseVo, HomeAreaSequenceResponseVo } from '@schemas/non-auth'
import Section from '@components/common/Section/Section'
import SectionTitle from '@components/common/Section/SectionTitle'
import UIContainer from '@components/ui/UIContainer'
import ListTemplate from '@components/list'
import getTemplateData from '@utils/getTemplateData'

export type SelectionSectionData = HomeAreaSequenceResponseVo & CommonAreaResponseVo
interface SectionProps {
	data: SelectionSectionData
}

const SelectionSection = ({ data }: SectionProps) => {
	const {
		areaItemList,
		issueInfo,
		bgMoImgUrl,
		bgTbImgUrl,
		bgPcImgUrl,
		isStaticBackgroundColor,
		linkUrlInfo,
	} = data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }

	const artcleList = getTemplateData(areaItemList)
	return (
		(artcleList?.length ?? 0) > 0 && (
			<Section
				bgColor={isStaticBackgroundColor}
				{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
			>
				<UIContainer>
					{data.areaName && data.areaName !== '' && (
						<SectionTitle
							isDark={!!src && !isStaticBackgroundColor}
							showLine={!src && !isStaticBackgroundColor}
							showGoButton={!!linkUrlInfo}
							link={linkUrlInfo}
						>
							{data.areaName}
						</SectionTitle>
					)}

					<ListTemplate
						type="article"
						list={artcleList}
						issue={issueInfo}
						isDark={!!src && !isStaticBackgroundColor}
						idx={data.templateIdx}
						areaIdx={data.areaIdx}
					/>
				</UIContainer>
			</Section>
		)
	)
}
export default SelectionSection

import { SeriesOrCornerAreaResponseVo, HomeAreaSequenceResponseVo } from '@schemas/non-auth'
import Section from '@components/common/Section/Section'
import SectionTitle from '@components/common/Section/SectionTitle'
import ListRecommendedCorner from '@components/list/ListRecommendedCorner'
import UIContainer from '@components/ui/UIContainer'

export type SeriesCornerSectionData = HomeAreaSequenceResponseVo & SeriesOrCornerAreaResponseVo
interface SectionProps {
	data: SeriesCornerSectionData
}

const SeriesCornerSection = ({ data }: SectionProps) => {
	const {
		cornerTagAreaItemList: seriesList,
		bgMoImgUrl,
		bgTbImgUrl,
		bgPcImgUrl,
		isStaticBackgroundColor,
	} = data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }
	return (
		(seriesList?.length ?? 0) > 0 && (
			<Section
				bgColor={isStaticBackgroundColor}
				{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
			>
				<UIContainer>
					{data.areaName && data.areaName !== '' && (
						<SectionTitle
							href="/series"
							showGoButton
							isDark={!!src && !isStaticBackgroundColor}
							showLine={!src && !isStaticBackgroundColor}
						>
							{data.areaName}
						</SectionTitle>
					)}
					<ListRecommendedCorner list={seriesList} areaIdx={data.areaIdx} />
				</UIContainer>
			</Section>
		)
	)
}
export default SeriesCornerSection

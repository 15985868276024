import { HomeAreaSequenceResponseVo, IssueAreaResponseVo } from '@schemas/non-auth'
import Section from '@components/common/Section/Section'
import SectionTitle from '@components/common/Section/SectionTitle'
import UIContainer from '@components/ui/UIContainer'
import ListTemplate from '@components/list'
import { getAreaParams, getPackageLinkType } from '@utils/getLink'
import pxToRem from '@utils/pxToRem'

export type IssueTopicSectionData = HomeAreaSequenceResponseVo & IssueAreaResponseVo
interface SectionProps {
	data: IssueTopicSectionData
}

const IssueTopicSection = ({ data }: SectionProps) => {
	const { areaItemList, bgMoImgUrl, bgTbImgUrl, bgPcImgUrl, isStaticBackgroundColor } = data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }
	const [packageData] = areaItemList
	const { packageIdx, packageCode } = packageData
	const packageLink =
		packageCode?.code && packageIdx
			? `/${getPackageLinkType(packageCode.code)}/${packageIdx}?${getAreaParams(
					'HOME',
					data.areaIdx,
			  )}`
			: undefined
	return (
		(areaItemList?.length ?? 0) > 0 && (
			<Section
				bgColor={isStaticBackgroundColor}
				{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
			>
				<UIContainer>
					{packageData?.itemTitle && packageData.itemTitle !== '' && (
						<SectionTitle
							{...(packageLink ? { href: packageLink, showGoButton: true } : {})}
							isDark={!!src && !isStaticBackgroundColor}
							showLine={!src && !isStaticBackgroundColor}
							sx={{
								marginBottom: pxToRem(64),
							}}
						>
							{packageData.itemTitle}
						</SectionTitle>
					)}
					<ListTemplate
						key={packageData?.packageIdx}
						list={packageData?.articlelist}
						isDark={!!src && !isStaticBackgroundColor}
						idx={data.templateIdx}
						areaIdx={data.areaIdx}
					/>
				</UIContainer>
			</Section>
		)
	)
}
export default IssueTopicSection

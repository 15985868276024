'use client'

import UIFlex, { UIFlexProps } from '@components/ui/UIFlex'
import { pxToRem } from '@ui/style/muiTheme'
import { Box } from '@mui/joy'
import Thumbnail from '../ContentCard/Thumbnail'
import {
	ContentProps,
	LayoutConfigProps,
	ThumbnailConfigProps,
	InfoConfigProps,
} from '../ContentCard'
import ContentInfo from '../ContentCard/ContentInfo'
import ContentInfoWrapper from './ContentInfoWrapper'
import { theme } from '@ui/style'
import { Property } from 'csstype'

export interface LargeContentCardProps {
	content: ContentProps
	layoutConfig?: LayoutConfigProps
	thumbnailConfig?: ThumbnailConfigProps
	infoConfig?: InfoConfigProps
	isInsetLayout?: boolean
	extraContent?: React.ReactNode
}

const LargeContentCard = ({
	content,
	layoutConfig = {},
	thumbnailConfig = {},
	infoConfig = {},
	isInsetLayout,
	extraContent,
}: LargeContentCardProps) => {
	const {
		articleIdx,
		src,
		alt,
		href,
		query,
		duration,
		title,
		bullet,
		bulletColor,
		body,
		additionalText,
		showVideoViewIcon,
		showLiveBadge,
		showBookmark,
		isBookmarked,
		vodInfo,
	} = content
	const { flexDirection = 'column', layoutGap = pxToRem(12), layoutSx } = layoutConfig
	// large card는 메인에서만 쓰이므로 isContain = false
	const {
		thumbWidth,
		thumbHeight,
		badgeInset,
		thumbSx,
		thumbRatio,
		isContain = false,
	} = thumbnailConfig
	const {
		isDark,
		infoGap,
		titleLevel,
		bodyLevel,
		infoSx,
		titleClamp,
		bodyClamp,
		showBody = true,
		showAdditionalText = true,
	} = infoConfig

	const isVideoView = !!(href && (href.includes('/video') || href.includes('/shorts')))
	const isShorts = href?.includes('/shorts') || vodInfo?.videoType?.code === 'SHORT_FORM'
	return (
		<UIFlex
			className="large-contentCard"
			color="initial"
			flexDirection={flexDirection}
			alignItems="stretch"
			position="relative"
			sx={{
				'& > *': {
					...Object.keys(theme.breakpoints.values).reduce((acc, breakpoint) => {
						const direction =
							typeof flexDirection === 'object'
								? (
										flexDirection as {
											[key: string]: Property.FlexDirection | undefined
										}
								  )[breakpoint]
								: (flexDirection as Property.FlexDirection)

						if (direction === undefined) return acc

						return {
							...acc,
							[theme.breakpoints.up(
								breakpoint as keyof typeof theme.breakpoints.values,
							)]: {
								marginLeft: direction === 'row' ? layoutGap : 0,
								marginRight: direction === 'row-reverse' ? layoutGap : 0,
								marginTop: direction === 'column' ? layoutGap : 0,
								marginBottom: direction === 'column-reverse' ? layoutGap : 0,
							},
						}
					}, {}),
				},
				'& > *:first-child': {
					marginLeft: 0,
					marginRight: 0,
					marginTop: 0,
					marginBottom: 0,
				},
				[theme.breakpoints.up('lg')]: {
					'& .thumb-liveBadge': {
						'& > *': { minWidth: pxToRem(63), pl: pxToRem(9), pr: pxToRem(8) },
						'.MuiTypography-root': {
							fontSize: pxToRem(16),
							lineHeight: pxToRem(18),
							span: { ml: pxToRem(6) },
							svg: { width: pxToRem(6) },
						},
					},
				},
				...layoutSx,
			}}
		>
			<Thumbnail
				src={src}
				alt={alt}
				thumbWidth={thumbWidth}
				thumbHeight={thumbHeight}
				showLiveBadge={showLiveBadge}
				showVideoViewIcon={showVideoViewIcon ?? isVideoView}
				duration={duration}
				badgeInset={badgeInset}
				thumbSx={thumbSx}
				thumbRatio={thumbRatio}
				href={href}
				query={query}
				isContain={isShorts ? true : isContain}
			/>
			<ContentInfoWrapper ownerState={{ isInsetLayout }} className="largeContentCard-wrapper">
				<ContentInfo
					articleIdx={articleIdx}
					infoGap={infoGap}
					titleLevel={titleLevel}
					bodyLevel={bodyLevel}
					title={title}
					titleClamp={titleClamp}
					bullet={bullet}
					bulletColor={bulletColor}
					body={body}
					bodyClamp={bodyClamp}
					additionalText={additionalText}
					isDark={isInsetLayout || isDark}
					infoSx={infoSx}
					showBody={showBody}
					href={href}
					query={query}
					showBookmark={showBookmark}
					isBookmarked={isBookmarked}
					showAdditionalText={showAdditionalText}
				/>
				{extraContent && (
					<Box className="largeContentCard-extraContent">{extraContent}</Box>
				)}
			</ContentInfoWrapper>
		</UIFlex>
	)
}

export default LargeContentCard

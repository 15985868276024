'use client'

import { Box, Grid } from '@mui/joy'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import { pxToRem } from '@ui/style/muiTheme'
import LargeContentCard, { LargeContentCardProps } from '@components/content/LargeContentCard'
import { theme } from '@ui/style'
import UIText from '@components/ui/UIText'
import UIFlex from '@components/ui/UIFlex'
import { IconGoIcons } from 'src/assets/icons/IconGoIcons'
import UILink from '@components/ui/UILink'
import color from '@ui/style/color.theme'
import { IssueInfo, IssueItem } from '@schemas/non-auth'
import { DATE_FORMAT, getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import dayjs from 'dayjs'
import VerticalTimelineItem from './VerticalTimelineItem'
import { ArticleItemProps } from '..'
import { getAreaParams, getArticleLink, getPackageLinkType } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'
import { IconGoIconMainIssue } from 'src/assets/icons/IconGoIconMainIssue'

const ListLgimageIssue = ({
	type,
	list = [],
	issue,
	isDark,
	areaIdx,
}: {
	type?: string
	list: Array<ArticleItemProps>
	issue?: IssueInfo
	isDark?: boolean
	areaIdx?: number
}) => {
	const [article1st, ...articleList] = list
	const largeCardProps: Omit<LargeContentCardProps, 'content'> = {
		layoutConfig: {
			layoutSx: {
				margin: { xs: `0 -${pxToRem(20)}`, md: 0 },
			},
		},
		thumbnailConfig: {
			badgeInset: { xs: pxToRem(8), lg: pxToRem(20) },
			thumbRatio: '1',
			thumbWidth: {
				md: pxToRem(434),
				lg: pxToRem(560),
				xl: pxToRem(800),
			},
			thumbHeight: {
				md: pxToRem(236),
				lg: pxToRem(312),
				xl: pxToRem(424),
			},
			thumbSx: {
				'.thumb-liveBadge': {
					[theme.breakpoints.up('lg')]: {
						marginTop: pxToRem(-4),
					},
				},
			},
		},
		infoConfig: {
			isDark,
			titleLevel: 'DisplayXL',
			infoSx: {
				[theme.breakpoints.down('md')]: {
					'.info-additionalBox': {
						marginBottom: pxToRem(8),
					},
				},
				[theme.breakpoints.up('md')]: {
					'.info-additionalBox': {
						marginBottom: pxToRem(8),
					},
					'.info-title': {
						fontSize: pxToRem(22),
					},
				},
				[theme.breakpoints.up('lg')]: {
					'.info-title': {
						width: pxToRem(410),
						fontSize: pxToRem(28),
					},
					'.info-additionalBox': {
						marginBottom: pxToRem(12),
					},
				},
				[theme.breakpoints.up('xl')]: {
					'.info-title': {
						width: pxToRem(605),
						fontSize: pxToRem(32),
					},
					'.info-additionalBox': {
						marginBottom: pxToRem(16),
					},
				},
			},
		},
		isInsetLayout: true,
	}

	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: 'row-reverse',
			layoutGap: pxToRem(16),
			layoutSx: {
				alignItems: 'flex-start',
				height: {
					xl: pxToRem(69.5),
				},
			},
		},
		thumbnailConfig: {
			thumbWidth: pxToRem(120),
			thumbHeight: pxToRem(68),
			thumbRatio: '16/9',
			badgeInset: pxToRem(6),
			thumbSx: {
				display: { xs: 'block', md: 'none', xl: 'block' },
			},
		},
		infoConfig: {
			isDark,
			titleClamp: 2,
			infoGap: pxToRem(4),
			infoSx: {
				flexDirection: 'column-reverse',
				'.info-additionalBox': {
					marginTop: 0,
					marginBottom: pxToRem(4),
				},
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						md: pxToRem(15),
						lg: pxToRem(16),
						xl: pxToRem(17),
					},
					lineHeight: {
						xs: pxToRem(22),
						xl: pxToRem(24),
					},
					WebkitLineClamp: {
						md: 1,
						xl: 2,
					},
				},
			},
		},
	}

	const issueList = issue?.issueItemList?.map(
		({ articleInfo, issueItemCode, liveInfo }: IssueItem) =>
			issueItemCode === 'ARTICLE'
				? {
						...articleInfo,
						code: issueItemCode,
				  }
				: {
						articleInnerTextContent: null,
						articleIdx: liveInfo.liveIdx,
						articleThumbnailImgUrl: liveInfo.liveThumbnailImgUrl,
						articleTitle: liveInfo.liveTitle,
						bulletType: liveInfo.bulletType,
						isScrap: null,
						isVideoView: false,
						journalistName: '',
						publicationDate: liveInfo.liveStartDate,
						rank: 0,
						code: issueItemCode,
						vodInfo: undefined,
				  },
	)
	const packageCode = issue?.packageCode?.code

	return (
		<Grid
			container
			columnSpacing={{ xs: 0, md: pxToRem(20), lg: pxToRem(24) }}
			rowSpacing={{ xs: pxToRem(16), md: 0 }}
			className="list-LGimageIssue"
		>
			<Grid
				xs={12}
				md={7.361}
				lg={7.361}
				xl={7.975}
				sx={{
					[theme.breakpoints.down('md')]: {
						'.MuiGrid-container:first-child .set-bg &': {
							marginTop: theme.pxToRem(-14),
						},
					},
				}}
			>
				{article1st && (
					<LargeContentCard
						content={{
							src:
								(article1st?.vodInfo
									? article1st?.vodInfo.vodThumbnailImgUrl
									: article1st?.articleThumbnailImgUrl) ?? imgDefaultH,
							href: getArticleLink(article1st, 'HOME', areaIdx),
							alt: '',
							title: article1st?.articleTitle,
							additionalText: getRelativeTime(
								article1st?.publicationDate ?? '',
								article1st?.code === 'LIVE',
							),
							bullet: article1st?.bulletType && article1st.bulletType.name,
							bulletColor:
								article1st?.bulletType &&
								getBulletColor(article1st.bulletType.name),
							showLiveBadge: article1st?.code === 'LIVE',
							vodInfo: article1st?.vodInfo,
						}}
						layoutConfig={largeCardProps.layoutConfig}
						thumbnailConfig={largeCardProps.thumbnailConfig}
						infoConfig={largeCardProps.infoConfig}
						isInsetLayout={largeCardProps.isInsetLayout}
					/>
				)}
			</Grid>
			<Grid
				component={UIFlex}
				xs={12}
				md={4.639}
				lg={4.639}
				xl={4.025}
				sx={{
					'&>*:not(:first-child)': {
						marginTop: {
							xs: pxToRem(16),
							md: pxToRem(20),
							lg: pxToRem(22),
						},
					},
				}}
			>
				{issue && (
					<UIFlex
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
						{...(packageCode && packageCode !== 'SPECIAL'
							? {
									component: UILink,
									href: `/${getPackageLinkType(packageCode)}/${
										issue.packageIdx
									}?${getAreaParams('HOME', areaIdx)}`,
							  }
							: {})}
					>
						<UIText
							color={isDark ? color.colWhite : color.colBlack}
							fontWeight="700"
							lineClamp={1}
							fontSize={{
								xs: pxToRem(22),
								md: pxToRem(18),
								lg: pxToRem(20),
								xl: pxToRem(24),
							}}
							lineHeight={{
								xs: pxToRem(22),
								md: pxToRem(24),
								xl: pxToRem(32),
							}}
						>
							{issue.packageName}
						</UIText>
						<Box
							flexShrink={0}
							sx={{
								[theme.breakpoints.down('md')]: {
									'& .icon-goIcons-xs': {
										display: 'block',
									},
									'& .icon-goIcons-md': {
										display: 'none',
									},
									'& .icon-goIcons-xl': {
										display: 'none',
									},
								},
								[theme.breakpoints.up('md')]: {
									'& .icon-goIcons-xs': {
										display: 'none',
									},
									'& .icon-goIcons-md': {
										display: 'block',
									},
									'& .icon-goIcons-xl': {
										display: 'none',
									},
								},
								[theme.breakpoints.up('xl')]: {
									'& .icon-goIcons-xs': {
										display: 'none',
									},
									'& .icon-goIcons-md': {
										display: 'none',
									},
									'& .icon-goIcons-xl': {
										display: 'block',
									},
								},
							}}
						>
							<IconGoIconMainIssue size="xs" className="icon-goIcons-xs" />
							<IconGoIconMainIssue size="md" className="icon-goIcons-md" />
							<IconGoIconMainIssue size="xl" className="icon-goIcons-xl" />
						</Box>
					</UIFlex>
				)}
				<Box
					sx={{
						'&>.MuiBox-root': {
							[theme.breakpoints.only('md')]: {
								'&:nth-of-type(3)': {
									paddingBottom: 0,
									'&>.MuiBox-root': {
										paddingBottom: 0,
										borderBottom: 0,
									},
								},
								'&:nth-of-type(4)': {
									display: 'none',
								},
							},
						},
					}}
				>
					{((issueList?.length ?? 0) > 0 ? issueList : articleList)
						?.sort(
							(a, b) =>
								Number(
									dayjs((type === 'live' ? a : b).publicationDate).format(
										DATE_FORMAT.FULL_AS_NUMBER,
									),
								) -
								Number(
									dayjs((type === 'live' ? b : a).publicationDate).format(
										DATE_FORMAT.FULL_AS_NUMBER,
									),
								),
						)
						?.map(
							(item, index) =>
								index < 4 && (
									<VerticalTimelineItem
										key={`${type}-item-${item.articleIdx}-${areaIdx}-${type}`}
										{...(index === 3 && { className: 'will-hide' })}
										isDark={isDark}
									>
										<ContentCard
											content={{
												articleIdx: item?.articleIdx.toString(),
												title: item.articleTitle,
												href: getArticleLink(
													item as unknown as ArticleItemProps,
													'HOME',
													areaIdx,
												),
												src: item?.articleThumbnailImgUrl ?? undefined,
												alt: '',
												additionalText: getRelativeTime(
													item?.publicationDate ?? '',
													item?.code === 'LIVE',
												),
												duration:
													item?.code !== 'LIVE'
														? (item as ArticleItemProps)?.vodInfo
																?.playTime
														: undefined,
												bullet: item?.bulletType && item.bulletType.name,
												bulletColor:
													item?.bulletType &&
													getBulletColor(item.bulletType.name),
												showLiveBadge: item?.code === 'LIVE',
												vodInfo: item?.vodInfo,
											}}
											layoutConfig={cardProps.layoutConfig}
											thumbnailConfig={cardProps.thumbnailConfig}
											infoConfig={cardProps.infoConfig}
										/>
									</VerticalTimelineItem>
								),
						)}
				</Box>
			</Grid>
		</Grid>
	)
}

export default ListLgimageIssue

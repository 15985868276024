'use client'

import { useQuery } from '@tanstack/react-query'
import { queryLive } from '.'
import { NewHomeReqModel } from '../types'
import { useGetCookieHeader } from '@store/cookieHeader'

export const useLive = (props: NewHomeReqModel) => {
	const cookieHeader = useGetCookieHeader()
	const { data, ...rest } = useQuery(queryLive(props, cookieHeader))
	return { data: data?.data, ...rest }
}

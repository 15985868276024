import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { Box, List, ListItem } from '@mui/joy'
import { styled } from '@mui/joy/styles'
import { fontSize, marginLeft, marginTop } from 'styled-system'

export const CorrectionBox = styled(UIFlex)(({ theme }) => ({
	position: 'relative',
	padding: theme.pxToRem(19),
	paddingTop: theme.pxToRem(15),
	border: '1px solid',
	borderColor: theme.color.colGray3,
	'&>*:not(:first-child)': {
		marginTop: theme.pxToRem(16),
	},
	[theme.breakpoints.up('md')]: {
		paddingBottom: theme.pxToRem(15),
		flexDirection: 'row',
		alignItems: 'flex-start',
		'&>*:not(:first-child)': {
			marginLeft: theme.pxToRem(36),
			marginTop: 0,
		},
	},
	[theme.breakpoints.up('lg')]: {
		'&>*:not(:first-child)': {
			marginLeft: theme.pxToRem(64),
			marginTop: 0,
		},
	},
}))

export const CorrectionTitle = styled(UIText)(({ theme }) => ({
	fontSize: theme.pxToRem(16),
	lineHeight: theme.pxToRem(24),
	[theme.breakpoints.only('md')]: {
		fontSize: theme.pxToRem(14),
	},
	[theme.breakpoints.up('md')]: {
		lineHeight: theme.pxToRem(20),
	},
}))

export const CorrectionMoreAnchor = styled(UILink)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		position: 'absolute',
		top: theme.pxToRem(15),
		right: theme.pxToRem(19),
		'& .icon-go': {
			width: theme.pxToRem(24),
			height: theme.pxToRem(24),
		},
	},
}))

export const CorrectionContent = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		width: 'calc(100% - 114px)',
		paddingRight: theme.pxToRem(24),
	},
}))

export const CorrectionList = styled(List)(({ theme }) => ({
	position: 'relative',
	padding: 0,
	'&>*:not(:first-child)': {
		marginTop: theme.pxToRem(12),
	},
	'--ListItem-minHeight': 'auto',
	[theme.breakpoints.up('md')]: {
		alignItems: 'flex-start',
		'&>*:not(:first-child)': {
			marginTop: theme.pxToRem(8),
		},
	},
	[theme.breakpoints.up('lg')]: {
		'&>*:not(:first-child)': {
			marginTop: theme.pxToRem(12),
		},
	},
}))

export const CorrectionItem = styled(ListItem)(({ theme }) => ({
	position: 'relative',
	padding: 0,
	paddingTop: theme.pxToRem(11),
	borderTop: '1px solid',
	borderTopColor: theme.color.colGray3,
	[theme.breakpoints.up('md')]: {
		padding: 0,
		borderTop: 'unset',
	},
}))

export const CorrectionAnchor = styled(UILink)(({ theme }) => ({
	width: '100%',
	justifyContent: 'space-between',
	flexGrow: 0,
	flexShrink: 1,
	'&>*:not(:first-child)': {
		marginLeft: theme.pxToRem(12),
	},
	[theme.breakpoints.up('md')]: {
		justifyContent: 'flex-start',
	},
}))

export const CorrectionText = styled(UIText)(({ theme }) => ({
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(16),
	fontWeight: 500,
	[theme.breakpoints.up('md')]: {
		lineHeight: theme.pxToRem(20),
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: theme.pxToRem(16),
	},
}))

export const CorrectionDate = styled(UIText)(({ theme }) => ({
	whiteSpace: 'nowrap',
	flexGrow: 0,
	flexShrink: 0,
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(16),
	opacity: 0.6,
	fontWeight: 500,
	[theme.breakpoints.up('md')]: {
		lineHeight: theme.pxToRem(20),
	},
}))

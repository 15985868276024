import {
	CommonAreaResponseVo,
	HomeAreaSequenceResponseVo,
	LiveScheduleResponseVo,
} from '@schemas/non-auth'
import Section from '@components/common/Section/Section'
import UIContainer from '@components/ui/UIContainer'
import ListTemplate from '@components/list'
import getTemplateData from '@utils/getTemplateData'
import NewsScheduleSection from './NewsSchedule'

export type NewsImportantSectionData = HomeAreaSequenceResponseVo & CommonAreaResponseVo
interface SectionProps {
	data: NewsImportantSectionData
	isLive?: boolean
	scheduleList: Array<LiveScheduleResponseVo>
}

const NewsImportantSection = ({ data, isLive, scheduleList }: SectionProps) => {
	const { areaItemList, issueInfo, bgMoImgUrl, bgTbImgUrl, bgPcImgUrl, isStaticBackgroundColor } =
		data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }
	const artcleList = getTemplateData(areaItemList)
	return (
		(artcleList?.length ?? 0) > 0 && (
			<>
				<Section
					bgColor={isStaticBackgroundColor}
					{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
				>
					<UIContainer>
						<ListTemplate
							type="article"
							list={artcleList}
							issue={issueInfo}
							isDark={!!src && !isStaticBackgroundColor}
							idx={data.templateIdx}
							areaIdx={data.areaIdx}
						/>
					</UIContainer>
				</Section>
				{isLive === false && <NewsScheduleSection scheduleList={scheduleList} />}
			</>
		)
	)
}
export default NewsImportantSection

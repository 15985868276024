import { Box, BoxProps } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

interface VerticalTimelineItemProps extends BoxProps {
	isDark?: boolean
}
const VerticalTimelineItem = ({ children, isDark, ...props }: VerticalTimelineItemProps) => {
	return (
		<Box
			pl={pxToRem(21)}
			pb={{ xs: pxToRem(12), md: pxToRem(16), xl: pxToRem(16) }}
			{...props}
			sx={{
				position: 'relative',
				'&:first-of-type': {
					'&::before': {
						height: `calc(100% - ${pxToRem(6)})`,
						top: pxToRem(5),
					},
				},
				'&:last-of-type': {
					paddingBottom: 0,
					'&>.MuiBox-root': {
						paddingBottom: 0,
						borderBottom: 0,
					},
				},
				'&::before': {
					content: '""',
					width: '1px',
					height: '100%',
					background: isDark ? 'rgba(255,255,255,0.2)' : color.colGray3,
					position: 'absolute',
					top: 0,
					left: pxToRem(4),
				},
				'&::after': {
					content: '""',
					width: pxToRem(6),
					height: pxToRem(6),
					borderRadius: '100px',
					background: color.colMintBlue,
					position: 'absolute',
					left: pxToRem(1.5),
					top: pxToRem(6),
				},
			}}
		>
			<Box
				pb={{ xs: pxToRem(12), md: pxToRem(13) }}
				borderBottom={`1px solid ${isDark ? 'rgba(255,255,255,0.2)' : color.colGray3}`}
			>
				{children}
			</Box>
		</Box>
	)
}
export default VerticalTimelineItem

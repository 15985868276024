'use client'

import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import UIDivider from '@components/ui/UIDivider'
import { Grid } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import { ArticleItemProps } from '..'
import { createXNCardContent } from '../createContent'
import { SxProps } from '@mui/joy/styles/types'

const List4XN = ({
	list = [],
	isDark,
	areaIdx,
	packageIdx,
	isVideoNews = false,
}: {
	list: Array<ArticleItemProps>
	isDark?: boolean
	areaIdx?: number
	packageIdx?: string
	isVideoNews?: boolean
}) => {
	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			layoutGap: { xs: pxToRem(12), md: pxToRem(16) },
		},
		thumbnailConfig: {
			thumbWidth: {
				xs: 'auto',
				md: pxToRem(224),
				lg: pxToRem(208),
				xl: pxToRem(280),
			},
			thumbHeight: {
				xs: 'auto',
				md: pxToRem(126),
				lg: pxToRem(117),
				xl: pxToRem(158),
			},
		},
		infoConfig: {
			isDark,
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(15),
						md: pxToRem(16),
						lg: pxToRem(17),
						xl: pxToRem(18),
					},
					lineHeight: {
						xs: pxToRem(22),
						lg: pxToRem(24),
						xl: pxToRem(26),
					},
				},
				'.info-additionalText': {
					fontSize: {
						xs: pxToRem(13),
						md: pxToRem(14),
					},
					lineHeight: {
						xs: pxToRem(16),
						md: pxToRem(18),
					},
				},
			},
		},
	}
	const handleDividerStyle = (index: number): SxProps => ({
		display: {
			xs: 'none',
			md: index % 3 !== 0 ? 'block' : 'none',
			lg: index % 4 !== 0 ? 'block' : 'none',
		},
	})
	const drawItem = (item: ArticleItemProps, index: number) => {
		return (
			<>
				{index !== 0 && (
					<UIDivider
						orientation="vertical"
						sx={handleDividerStyle(index)}
						isDark={isDark}
					/>
				)}
				<Grid xs={6} md={4} lg={3} key={`${item.articleIdx}-${item.articleTitle}`}>
					<ContentCard
						content={createXNCardContent({
							item,
							areaIdx,
							packageIdx,
							isVideoNews,
						})}
						layoutConfig={cardProps.layoutConfig}
						thumbnailConfig={cardProps.thumbnailConfig}
						infoConfig={cardProps.infoConfig}
					/>
				</Grid>
			</>
		)
	}

	return (
		<Grid
			container
			columnSpacing={{ xs: pxToRem(16), md: pxToRem(24), lg: pxToRem(32) }}
			mt={{
				xs: pxToRem(-32),
				xl: pxToRem(-36),
			}}
			sx={{
				'&>*': {
					marginTop: {
						xs: `${pxToRem(32)}!important`,
						xl: `${pxToRem(36)}!important`,
					},
				},
			}}
		>
			{list.map(drawItem)}
		</Grid>
	)
}

export default List4XN

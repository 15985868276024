import { ArticleListResponseVo } from '@schemas/non-auth'
import { pxToRem } from '@ui/style/muiTheme'
import Grid from '@mui/joy/Grid'
import ArticleItem from './ArticleItem'

// MEMO: List4XN에서 ContentCard의 content만 다름.
const ListSubscribingIssue = ({
	list = [],
	isDark,
	areaIdx,
}: {
	list?: Array<ArticleListResponseVo>
	isDark?: boolean
	areaIdx?: number
}) => {
	const drawSubscribingIssue = (item: ArticleListResponseVo, index: number) =>
		index < 4 && (
			<ArticleItem
				key={`item-${item.articleIdx}-${areaIdx}}`}
				item={item}
				index={index}
				isDark={isDark}
				areaIdx={areaIdx}
			/>
		)
	return (
		<Grid
			container
			rowSpacing={{ xs: pxToRem(32), md: 0 }}
			columnSpacing={{ xs: pxToRem(16), md: pxToRem(24), lg: pxToRem(32) }}
		>
			{list?.map(drawSubscribingIssue)}
		</Grid>
	)
}

export default ListSubscribingIssue

import { ArticleListResponseVo } from '@schemas/non-auth'
import { Grid } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import ArticleItem from './ArticleItem'

const ListRecommendedArticle = ({
	list = [],
	isDark,
	areaIdx,
}: {
	list?: Array<ArticleListResponseVo>
	isDark?: boolean
	areaIdx?: number
}) => {
	const drawRecommendedArticle = (item: ArticleListResponseVo, articleIndex: number) => (
		<ArticleItem
			key={`article-item-${item?.articleIdx}-${areaIdx}`}
			item={item}
			index={articleIndex}
			isDark={isDark}
			areaIdx={areaIdx}
		/>
	)
	return (
		<Grid
			container
			columnSpacing={{ xs: 0, md: pxToRem(24), lg: pxToRem(32) }}
			mb={{
				xs: pxToRem(-12),
				md: pxToRem(-32),
				xl: pxToRem(-36),
			}}
			sx={{
				'&>*': {
					marginBottom: {
						xs: `${pxToRem(12)}!important`,
						md: `${pxToRem(32)}!important`,
						xl: `${pxToRem(36)}!important`,
					},
				},
			}}
		>
			{list?.map(drawRecommendedArticle)}
		</Grid>
	)
}

export default ListRecommendedArticle

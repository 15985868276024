'use client'

import UIFlex from '@components/ui/UIFlex'
import { styled } from '@mui/joy'
import { theme } from '@ui/style'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

interface OwnerState {
	isInsetLayout?: boolean
}

export const insetLayoutStyle = {
	width: '100%',
	height: '100%',
	position: 'absolute',
	bottom: 0,
	left: 0,
	right: 0,
	'&>*,&>.MuiLink-root': {
		flexDirection: 'column-reverse',
		zIndex: 1,
		padding: `${pxToRem(28)} ${pxToRem(24)}`,
		[theme.breakpoints.up('md')]: {
			padding: `${pxToRem(20)} ${pxToRem(24)}`,
		},
		[theme.breakpoints.up('lg')]: {
			padding: `${pxToRem(28)} ${pxToRem(32)}`,
		},
		[theme.breakpoints.up('xl')]: {
			padding: `${pxToRem(32)} ${pxToRem(36)}`,
		},
	},
	'.info-additionalText': {
		color: color.colWhite,
		opacity: '0.6',
		fontSize: pxToRem(15),
		lineHeight: pxToRem(18),
	},
	'.info-title': {
		color: color.colWhite,
	},
	'&::before': {
		content: '""',
		display: 'block',
		width: '100%',
		height: '50%',
		position: 'absolute',
		bottom: '0',
		left: '0',
		right: '0',
		background:
			'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%, rgba(0, 0, 0, 0.80) 100%)',
	},
}

const ContentInfoWrapper = styled(UIFlex)(({ ownerState }: { ownerState: OwnerState }) => {
	const { isInsetLayout } = ownerState
	return isInsetLayout ? insetLayoutStyle : {}
})

export default ContentInfoWrapper

import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

import { theme } from '@ui/style'
import { UISlideItem } from '.'
import UIButton from '../UIButton'
import UILink from '../UILink'

const UISlideButton = ({
	option,
	active,
	handleActive,
}: {
	active?: string | number
	option: UISlideItem
	handleActive: (value: string | number) => void
}) => {
	const buttonSx = {
		paddingY: { xs: pxToRem(8), md: pxToRem(10) },
		paddingX: { xs: pxToRem(14), md: pxToRem(16) },
		fontSize: { xs: pxToRem(15), md: pxToRem(16) },
		lineHeight: { xs: pxToRem(24) },
		[theme.mediaQueries.hover]: {
			...(active !== option.value && {
				'&:hover, &:active': { background: color.colGray3 },
			}),
		},
	}

	const button = (
		<UIButton
			role="tab"
			aria-selected={active === option.value}
			color={active !== option.value ? 'neutralMain' : undefined}
			onClick={() => handleActive(option.value)}
			tabIndex={option.href ? -1 : undefined}
			sx={buttonSx}
		>
			{option.label}
		</UIButton>
	)

	return option.href ? (
		<UILink href={option.href} scroll={Boolean(option.href)}>
			{button}
		</UILink>
	) : (
		button
	)
}
export default UISlideButton

import { ShortFormNewsAreaResponseVo, HomeAreaSequenceResponseVo } from '@schemas/non-auth'
import Section from '@components/common/Section/Section'
import SectionTitle from '@components/common/Section/SectionTitle'
import ListShorts from '@components/list/ListShorts'
import UIContainer from '@components/ui/UIContainer'

export type ShortformNewsSectionData = HomeAreaSequenceResponseVo & ShortFormNewsAreaResponseVo
interface SectionProps {
	data: ShortformNewsSectionData
}

const ShortformNewsSection = ({ data }: SectionProps) => {
	const {
		shortFormNewsArticlelist: articleList,
		bgMoImgUrl,
		bgTbImgUrl,
		bgPcImgUrl,
		isStaticBackgroundColor,
	} = data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }
	return (
		(articleList?.length ?? 0) > 0 && (
			<Section
				bgColor={isStaticBackgroundColor}
				{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
			>
				<UIContainer>
					{data.areaName && data.areaName !== '' && (
						<SectionTitle
							isDark={!!src && !isStaticBackgroundColor}
							showLine={!src && !isStaticBackgroundColor}
						>
							{data.areaName}
						</SectionTitle>
					)}
					<ListShorts
						list={articleList}
						isDark={!!src && !isStaticBackgroundColor}
						areaIdx={data.areaIdx}
					/>
				</UIContainer>
			</Section>
		)
	)
}
export default ShortformNewsSection

import { Avatar, Box, Typography, styled } from '@mui/joy'
import Link from 'next/link'
import { theme } from '@ui/style'
import { pxToRem } from '@ui/style/muiTheme'

export const WrapperLink = styled(Link)`
	position: relative;
	display: block;
	background: ${theme.color.colGray1};
	border-radius: ${pxToRem(8)};
	padding: ${pxToRem(20)} ${pxToRem(24)};
	${theme.mediaQueries.over.lg} {
		padding: ${pxToRem(24)};
	}
`

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	& > *:not(:first-child) {
		margin-top: ${pxToRem(16)};
	}
`

export const ContentBadgeBox = styled(Box)`
	& p {
		color: ${theme.color.colWhite};
	}
`

export const ReporterAvatar = styled(Avatar)`
	width: ${pxToRem(84)};
	height: ${pxToRem(84)};
`

export const TextBox = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	& > *:not(:first-child) {
		margin-top: ${pxToRem(4)};
	}
	min-height: ${pxToRem(62)};
`

export const Title = styled(Typography)`
	width: 100%;
	text-align: center;
	color: ${theme.color.colBlack};
	font-size: ${pxToRem(16)};
	line-height: ${pxToRem(22)};
	font-weight: 700;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	${theme.mediaQueries.over.md} {
		line-height: ${pxToRem(24)};
	}
	${theme.mediaQueries.over.lg} {
		font-size: ${pxToRem(17)};
	}
`

export const SubTitle = styled(Typography)`
	max-width: ${pxToRem(138)};
	margin: auto;
	color: ${theme.color.colGray5};
	font-size: ${pxToRem(14)};
	font-weight: 500;
	line-height: ${pxToRem(18)};
	text-align: center;

	overflow: hidden;
	white-space: normal;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-all;
`

import Section from '@components/common/Section/Section'
import SectionTitle from '@components/common/Section/SectionTitle'
import ListSchedule from '@components/list/ListSchedule'
import UIContainer from '@components/ui/UIContainer'
import { LiveScheduleResponseVo } from '@schemas/non-auth'

interface NewsScheduleSectionProps {
	scheduleList: Array<LiveScheduleResponseVo>
}
const NewsScheduleSection = ({ scheduleList }: NewsScheduleSectionProps) => {
	return (
		(scheduleList?.length ?? 0) > 0 && (
			<Section bgColor>
				<UIContainer>
					<SectionTitle>뉴스 편성표</SectionTitle>
					<ListSchedule list={scheduleList} />
				</UIContainer>
			</Section>
		)
	)
}
export default NewsScheduleSection

'use client'

import { styled, ButtonProps } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import UIButton from '@components/ui/UIButton'
import MuiTypography from '@mui/joy/Typography'
import { forwardRef } from 'react'
import UIText from '@components/ui/UIText'
import { theme } from '@ui/style'

const SeeAllButtonStyled = styled(UIButton)`
	background: ${theme.color.colWhite};
	height: ${pxToRem(54)};
	&:hover {
		background: ${theme.color.colWhite};
	}
	${theme.mediaQueries.hover} {
		&:hover {
			background: ${theme.color.colGray1};
		}
	}
	${theme.mediaQueries.under.md} {
		font-size: ${pxToRem(15)};
	}
	&:active {
		background: ${theme.color.colGray1};
	}
`
const SubText = styled(MuiTypography)`
	color: ${theme.color.colGray5};
	font-size: ${pxToRem(16)};
	font-weight: 500;
	line-height: ${pxToRem(18)};
	${theme.mediaQueries.under.md} {
		font-size: ${pxToRem(15)};
	}
`

type SeeAllButtonProps = ButtonProps & {
	title?: string
	tabIndex?: number
}

export const SeeAllButton = forwardRef<HTMLButtonElement, SeeAllButtonProps>(
	({ title, tabIndex, ...props }, ref) => {
		return (
			<SeeAllButtonStyled
				{...props}
				ref={ref}
				variant="outlined"
				color="neutral"
				size="xl"
				tabIndex={tabIndex}
				className="button-all"
			>
				{title && <UIText>{title}</UIText>}
				<SubText>전체보기</SubText>
			</SeeAllButtonStyled>
		)
	},
)

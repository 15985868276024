'use client'

import { ArticleListResponseVo, RecommendIssueAreaItem } from '@schemas/non-auth'
import { pxToRem } from '@ui/style/muiTheme'
import color from '@ui/style/color.theme'
import Box from '@mui/joy/Box'
import { SubscribeButton } from '@components/button/SubscribeButton'
import Carousel from '@components/common/Carousel/Carousel'
import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import ArticleItem from './ArticleItem'
import { getAreaParams } from '@utils/getLink'
import { Icon16Person } from 'src/assets/icons/Icon16Person'
import { marginRight } from 'styled-system'

const ListRecommendedIssue = ({
	list = [],
	isDark,
	areaIdx,
}: {
	list?: Array<RecommendIssueAreaItem>
	isDark?: boolean
	areaIdx?: number
}) => {
	const drawRecommendedIssueItem = (
		{ packageIdx, itemTitle, issueArticlelist, isSubscribe }: RecommendIssueAreaItem,
		packageIndex: number,
	) => {
		const drawIssueArticleItem = (item: ArticleListResponseVo, articleIndex: number) => (
			<ArticleItem
				key={`article-item-${packageIdx}-${item.articleIdx}-${areaIdx}`}
				item={item}
				index={articleIndex}
				isDark={isDark}
				areaIdx={areaIdx}
			/>
		)
		return (
			<UIFlex
				key={`package-${packageIdx}`}
				paddingX={{
					xs: pxToRem(20),
					md: pxToRem(16),
					xl: pxToRem(24),
				}}
				height="100%"
				{...(packageIndex + 1 !== list.length && {
					borderRight: `1px solid ${isDark ? 'rgba(255,255,255,.2)' : color.colGray3}`,
					paddingRight: {
						xs: pxToRem(19),
						md: pxToRem(15),
						xl: pxToRem(23),
					},
				})}
				sx={{
					'&> *:not(:first-child)': { marginTop: { xs: pxToRem(16), xl: pxToRem(20) } },
				}}
			>
				<UIFlex flexDirection="row" alignItems="center" justifyContent="space-between">
					<Box>
						<UIText
							color={isDark ? color.colWhite : color.colBlack}
							lineClamp={1}
							fontSize={{ xs: pxToRem(17), lg: pxToRem(18) }}
							lineHeight={pxToRem(24)}
							fontWeight={700}
							mr={pxToRem(16)}
							{...{
								component: UILink,
								href: `/issues/${packageIdx}?${getAreaParams('HOME', areaIdx)}`,
							}}
						>
							{itemTitle}
						</UIText>
					</Box>
					<SubscribeButton
						isSubscribed={isSubscribe ?? false}
						target="package"
						idx={packageIdx?.toString()}
					/>
				</UIFlex>
				<UIFlex sx={{ '&> *:not(:first-child)': { marginTop: pxToRem(16) } }}>
					{issueArticlelist?.slice(0, 3).map(drawIssueArticleItem)}
				</UIFlex>
			</UIFlex>
		)
	}

	return (
		<Box overflow={{ xs: 'visible', md: 'hidden' }}>
			<Box
				mx={{ xs: pxToRem(-20), md: pxToRem(-16), xl: pxToRem(-24) }}
				sx={(theme) => ({
					'.swiper-button-prev': {
						marginLeft: pxToRem(17),
					},
					'.swiper-button-next': {
						marginRight: pxToRem(17),
					},
					'& .swiper:not(.swiper-initialized)': {
						[theme.breakpoints.down('md')]: {
							'& .swiper-slide': {
								width: `calc(100% / 1.15)`,
							},
						},
						[theme.breakpoints.up('md')]: {
							'& .swiper-slide': {
								width: `calc(100% / 2)`,
							},
						},
						[theme.breakpoints.up('lg')]: {
							'& .swiper-slide': {
								width: `calc(100% / 3)`,
							},
						},
					},
					[theme.breakpoints.down('md')]: {
						'& .swiper-slide': {
							width: `${pxToRem(329)}!important`,
						},
					},
				})}
			>
				<Carousel
					slidesPerView="auto"
					spaceBetween={0}
					navigation={false}
					breakpoints={{
						768: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 3,
						},
					}}
				>
					{list.map(drawRecommendedIssueItem)}
				</Carousel>
			</Box>
		</Box>
	)
}

export default ListRecommendedIssue

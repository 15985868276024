import { ApiResultVoSubscribeIssueAreaResponseVo } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { NewHomeReqModel } from '../types'
import { fetchAPI } from '@utils/http/apiRequest'

export type SubscribeIssueResponse = CommonResponse<ApiResultVoSubscribeIssueAreaResponseVo>
export type SubscribeIssueResponseData = ApiResultVoSubscribeIssueAreaResponseVo['data']

const path = '/restapi/v1/get/template/news-home/subscribe-issue'
const queryKeyString = () => ['@template/news-home/subscribe-issue'] as const

const getQuery = async (
	props: NewHomeReqModel,
	cookieHeader?: string,
): Promise<ApiResultVoSubscribeIssueAreaResponseVo> => {
	const { areaIdx, articleListType } = props
	const params = new URLSearchParams({
		...(areaIdx ? { areaIdx: String(areaIdx) } : {}),
		...(articleListType ? { articleListType: String(articleListType) } : {}),
	})
	return fetchAPI({ path, params, cookieHeader })
}

export const querySubscribeIssue = (props: NewHomeReqModel, cookieHeader?: string) => ({
	queryKey: [...queryKeyString()],
	queryFn: () => getQuery(props, cookieHeader),
})

export const prefetchSubscribeIssue = (props: NewHomeReqModel, cookieHeader?: string) =>
	createPrefetchQuery(queryKeyString(), () => getQuery(props, cookieHeader))

import { pxToRem } from '@ui/style/muiTheme'
import color from '@ui/style/color.theme'
import Box from '@mui/joy/Box'
import UIFlex, { UIFlexProps } from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import LiveReserveButton from '@components/button/LiveReserveButton'
import UIImage from '@components/ui/UIImage'
import { NewsScheduleStatus } from '@page/live/_component/LiveBody/NewsScheduleSection/NewsScheduleItem'
import UIButton from '@components/ui/UIButton'
import { IconMidDot } from 'src/assets/icons/IconMiddot'
import UILink from '@components/ui/UILink'

interface ScheduleItemProps extends Omit<UIFlexProps, 'ref'> {
	idx: number
	src: string
	alt?: string
	href: string
	title: string
	time?: string
	isReserved?: boolean
	status: NewsScheduleStatus
}
const ScheduleItem = ({
	idx,
	src,
	alt,
	href,
	title,
	time,
	isReserved,
	status,
	...props
}: ScheduleItemProps) => {
	return (
		<Box {...props}>
			<UIFlex
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				padding={{ xs: pxToRem(20), lg: `${pxToRem(20)} ${pxToRem(24)}` }}
				sx={{
					background: color.colWhite,
					'&> *:not(:first-child)': { marginLeft: pxToRem(16) },
					'& .MuiButton-root': {
						flex: 'none',
					},
				}}
				borderRadius={pxToRem(8)}
				overflow="hidden"
			>
				<UIFlex
					flexGrow="1"
					flexDirection="row"
					sx={{
						'&> *:not(:first-child)': { marginLeft: pxToRem(12) },
					}}
					alignItems="center"
					{...(status === 'live' ? { component: UILink, href } : {})}
				>
					<Box width={pxToRem(48)} borderRadius="9999px" overflow="hidden" flexShrink="0">
						<UIImage src={src} alt={alt ?? ''} ratio={1} />
					</Box>
					<UIFlex
						sx={{
							'&> *:not(:first-child)': { marginTop: pxToRem(4) },
						}}
					>
						<UIText
							fontSize={{ xs: pxToRem(17), lg: pxToRem(18) }}
							lineHeight={pxToRem(24)}
							fontWeight={700}
							color={color.colBlack}
							lineClamp={1}
						>
							{title}
						</UIText>
						<UIText
							level="CaptionTime"
							fontWeight={500}
							lineClamp={1}
							color={color.colGray5}
						>
							{time}
						</UIText>
					</UIFlex>
				</UIFlex>
				{status === 'live' && (
					<UIButton
						disabled
						sx={{
							'&.MuiButton-root': {
								background: color.colExclusiveRed,
								color: color.colWhite,
							},
						}}
					>
						<IconMidDot />
						<UIText
							color={color.colWhite}
							fontSize={pxToRem(14)}
							fontWeight={600}
							lineHeight={pxToRem(16)}
						>
							LIVE
						</UIText>
					</UIButton>
				)}
				{status === 'wait' && (
					<LiveReserveButton isReserved={isReserved} idx={idx} title={title} />
				)}
				{status === 'ended' && (
					<UIButton
						disabled
						// 이 화면에서만 쓰이는 disabled 버튼 배색으로 보임
						sx={{
							'&.MuiButton-root': {
								color: color.colGray5,
								background: color.colGray1,
							},
						}}
					>
						방송 종료
					</UIButton>
				)}
			</UIFlex>
		</Box>
	)
}

export default ScheduleItem

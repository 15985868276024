import { useQuery } from '@tanstack/react-query'
import { ApiResultVoRankNewsAreaResponseVo } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { NewHomeReqModel } from '../types'
import { fetchAPI } from '@utils/http/apiRequest'

export type RankNewsResponse = CommonResponse<ApiResultVoRankNewsAreaResponseVo>
export type RankNewsResponseData = ApiResultVoRankNewsAreaResponseVo['data']

const path = '/restapi/v1/get/template/news-home/rank-news'
const queryKeyString = (paramsKey: NewHomeReqModel | null) =>
	['@template/news-home/rank-news', paramsKey] as const

const getQuery = async (props: NewHomeReqModel): Promise<ApiResultVoRankNewsAreaResponseVo> => {
	const { areaIdx, rankType, pageSize, articleListType } = props
	const params = new URLSearchParams({
		...(areaIdx ? { areaIdx: String(areaIdx) } : {}),
		...(articleListType ? { articleListType: String(articleListType) } : {}),
		rankType: String(rankType ?? 'VIEW'),
		pageSize: String(pageSize ?? 20),
	})
	return fetchAPI({ path, params })
}

export const queryRankNews = (props: NewHomeReqModel) => ({
	queryKey: [...queryKeyString(props)],
	queryFn: () => getQuery(props),
})

export const useRankNews = (props: NewHomeReqModel) => {
	const { data, ...rest } = useQuery(queryRankNews(props))
	return { data: data?.data, ...rest }
}

export const prefetchRankNews = (props: NewHomeReqModel) =>
	createPrefetchQuery(queryKeyString(props), () => getQuery(props))

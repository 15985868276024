import { ArticleListResponseVo } from '@schemas/non-auth'
import { pxToRem } from '@ui/style/muiTheme'
import Grid from '@mui/joy/Grid'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import UIDivider from '@components/ui/UIDivider'
import { getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import { getArticleLink } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'

const ArticleItem = ({
	item,
	index,
	isDark,
	areaIdx,
}: {
	item: ArticleListResponseVo
	index: number
	isDark?: boolean
	areaIdx?: number
}) => {
	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			layoutGap: { xs: pxToRem(12), md: pxToRem(16) },
		},
		infoConfig: {
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						lg: pxToRem(17),
						xl: pxToRem(18),
					},
					lineHeight: {
						xs: pxToRem(22),
						lg: pxToRem(24),
						xl: pxToRem(26),
					},
				},
				'.info-additionalText': {
					fontSize: {
						xs: pxToRem(13),
						md: pxToRem(14),
					},
					lineHeight: {
						xs: pxToRem(16),
						md: pxToRem(18),
					},
				},
			},
		},
		thumbnailConfig: {
			thumbWidth: {
				xs: pxToRem(168.5),
				md: pxToRem(224),
				lg: pxToRem(208),
				xl: pxToRem(280),
			},
			thumbHeight: { xs: pxToRem(94), md: pxToRem(126), lg: pxToRem(117), xl: pxToRem(158) },
		},
	}
	return (
		<>
			{index > 0 && (
				<UIDivider
					orientation="vertical"
					isDark={isDark}
					sx={(theme) => ({
						display: { xs: 'none', md: 'block' },
						[theme.breakpoints.only('md')]: {
							'&:nth-last-child(2)': {
								display: 'none',
							},
						},
					})}
				/>
			)}
			<Grid
				xs={6}
				md={4}
				lg={3}
				sx={(theme) => ({
					[theme.breakpoints.only('md')]: {
						'&:last-child': {
							display: 'none',
						},
					},
				})}
			>
				<ContentCard
					content={{
						articleIdx: item?.articleIdx,
						title: item.articleTitle,
						href: getArticleLink(item, 'HOME', areaIdx),
						src:
							(item.vodInfo
								? item.vodInfo?.vodThumbnailImgUrl
								: item?.articleThumbnailImgUrl) ?? imgDefaultH,
						alt: '',
						additionalText: getRelativeTime(item?.publicationDate ?? ''),
						duration: item?.vodInfo?.playTime,
						bullet: item?.bulletType && item.bulletType.name,
						bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
						showBookmark: true,
						isBookmarked: item.isScrap ?? false, // 수정 필요
						vodInfo: item?.vodInfo,
					}}
					layoutConfig={cardProps.layoutConfig}
					infoConfig={{ isDark }}
				/>
			</Grid>
		</>
	)
}
export default ArticleItem

import { ArticleListResponseVo } from '@schemas/non-auth'
import { pxToRem } from '@ui/style/muiTheme'
import Box from '@mui/joy/Box'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import CSSgridList from '@components/list/CSSgridList'
import { CSSGridListBorder } from '@components/list/CSSgridList/CSSgridListRoot'
import { getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import { getArticleLink } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'

// MEMO: ListRankingNews에서 랭킹 숫자만 제거됨
const ListNewsByField = ({
	list = [],
	isDark,
	areaIdx,
}: {
	list?: Array<ArticleListResponseVo>
	isDark?: boolean
	areaIdx?: number
}) => {
	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: 'row-reverse',
			layoutGap: pxToRem(16),
			layoutSx: {
				height: {
					md: pxToRem(67.5),
					lg: pxToRem(74.25),
				},
			},
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(120), lg: pxToRem(132) },
			thumbHeight: { xs: pxToRem(68), lg: pxToRem(74) },
			badgeInset: pxToRem(6),
		},
		infoConfig: {
			titleLevel: 'HeadingM',
			infoSx: {
				'.info-title': {
					marginBottom: pxToRem(8),
				},
				'.info-additionalBox': {
					marginTop: 'auto',
				},
			},
			isDark,
		},
	}
	const displayToIndex = (index: number) => {
		if (index >= 8) {
			return { xs: 'none', xl: 'block' }
		}
		if (index >= 5) {
			return { xs: 'none', md: 'block' }
		}
		return undefined
	}

	return (
		<CSSgridList
			gridTemplateRows={{ md: 'repeat(4, 1fr)', xl: 'repeat(3, 1fr)' }}
			gridTemplateColumns={{ xs: '1fr', md: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }}
			gridAutoFlow={{ xs: 'row', md: 'column' }}
			isDark={isDark}
			sx={(theme) => ({
				[theme.breakpoints.down('md')]: {
					'& .article-item:nth-child(5)': {
						borderBottom: 0,
						paddingBottom: 0,
					},
					...CSSGridListBorder('horizontal', isDark),
				},
				[theme.breakpoints.up('md')]: {
					...CSSGridListBorder('both', isDark),
				},
			})}
		>
			{list?.map((item, index) => (
				<Box
					className="article-item"
					key={`article-${item.articleIdx}-${item.publicationDate}`}
					display={displayToIndex(index)}
				>
					<ContentCard
						content={{
							articleIdx: item?.articleIdx,
							title: item.articleTitle,
							href: getArticleLink(item, 'HOME', areaIdx),
							src: item?.articleThumbnailImgUrl ?? undefined,
							alt: '',
							additionalText: getRelativeTime(item?.publicationDate ?? ''),
							duration: item?.vodInfo?.playTime,
							bullet: item?.bulletType && item.bulletType.name,
							bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
							showBookmark: true,
							isBookmarked: item.isScrap ?? false, // 수정 필요
							vodInfo: item?.vodInfo,
						}}
						layoutConfig={cardProps.layoutConfig}
						thumbnailConfig={cardProps.thumbnailConfig}
						infoConfig={cardProps.infoConfig}
					/>
				</Box>
			))}
		</CSSgridList>
	)
}

export default ListNewsByField

'use client'

import { BoxProps } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import { useEffect, useRef, useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper } from 'swiper/react'
import { SwiperOptions, Swiper as SwiperType } from 'swiper/types'
import UISlideAnchor from './anchor'
import UISlideButton from './button'
import { SlideContainer, SlideItem, SlideWrap, TabNavButton, TabNavButtonGroup } from './styled'

export interface UISlideItem {
	value: string | number
	label: string
	href?: string
}
export interface UISlideTabProps extends SwiperOptions {
	button?: boolean
	link?: boolean
	size?: 'md' | 'lg'
	navigation?: boolean
	options?: UISlideItem[]
	current?: string | number
	setCurrent?: (value: string | number) => void
	sx?: BoxProps['sx']
}

/**
 * @param button tab 스타일이 버튼 타입인지 여부를 나타냅니다.
 * @param size 기본형일 경우, 'md', 'lg' 두 가지 중 택일, 버튼타입은 md 통일입니다.
 * @param navigation 네비게이션 버튼 표시 여부를 나타냅니댜. / default: 버튼형 탭 'false', 기본형 탭 'true'
 */
const UISlideTab = ({
	button,
	link,
	size = 'md',
	navigation = !button,
	options = [
		...new Array(2).fill(null).map((option, index) => ({
			label: ``,
			value: index,
		})),
	],
	current,
	setCurrent,
	sx,
	...swiperOptions
}: UISlideTabProps) => {
	const [active, setActive] = useState<string | number>()
	const swiperSlide = useRef<SwiperType>()
	const [swiperInfo, setSwiperInfo] = useState<SwiperType>()

	const handleActive = (value: string | number) => {
		setActive(value)
		setCurrent?.(value)
	}
	useEffect(() => {
		if (active === undefined) {
			handleActive(current ?? options[0]?.value)
		}
	}, [active, current])
	return (
		<SlideContainer button={button ? 'button' : ''} sx={sx}>
			<SlideWrap className="slide-wrapper">
				<Swiper
					slidesPerView="auto"
					role="tablist"
					onSwiper={(swiper: SwiperType) => {
						setSwiperInfo({ ...swiper })
						swiperSlide.current = swiper
					}}
					onSlideChange={(swiper: SwiperType) => {
						setSwiperInfo({ ...swiper })
					}}
					onTransitionEnd={(swiper: SwiperType) => {
						setSwiperInfo({ ...swiper })
					}}
					onResize={(swiper: SwiperType) => {
						setSwiperInfo({ ...swiper })
					}}
					{...swiperOptions}
				>
					{[...options].map((option, index) => (
						<SlideItem
							key={option.label}
							button={button ? 'button' : ''}
							sx={{
								marginLeft: { xs: index === 0 ? pxToRem(20) : 0, md: 0 },
								marginRight: {
									xs: options.length - 1 === index ? pxToRem(20) : 0,
									md: 0,
								},
							}}
						>
							{button ? (
								<UISlideButton
									option={option}
									active={active}
									handleActive={handleActive}
								/>
							) : (
								<UISlideAnchor
									link={link}
									active={active}
									selected={current}
									option={option}
									handleActive={handleActive}
									sx={{
										paddingTop: size === 'lg' ? { xs: pxToRem(16) } : '',
										paddingBottom: size === 'lg' ? { xs: pxToRem(14) } : '',
										fontSize: size === 'lg' ? { xs: pxToRem(16) } : '',
										lineHeight: size === 'lg' ? { xs: pxToRem(24) } : '',
									}}
								/>
							)}
						</SlideItem>
					))}
				</Swiper>
				{navigation && swiperInfo && (
					<TabNavButtonGroup>
						{swiperInfo.isBeginning}
						<TabNavButton
							direction="left"
							disabled={!!swiperInfo.isBeginning}
							size="md"
							stroked
							className="swiper-button-prev"
							onClick={() => {
								swiperSlide.current?.slidePrev()
							}}
							sx={{ left: { lg: 0 } }}
						/>
						<TabNavButton
							direction="right"
							disabled={!!swiperInfo.isEnd}
							size="md"
							stroked
							className="swiper-button-next"
							onClick={() => {
								swiperSlide.current?.slideNext()
							}}
							sx={{ right: { lg: 0 } }}
						/>
					</TabNavButtonGroup>
				)}
			</SlideWrap>
		</SlideContainer>
	)
}
export default UISlideTab

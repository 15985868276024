import UIFlex, { UIFlexProps } from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { IconRelatedBracket } from 'src/assets/icons/IconRelatedBracket'

interface RelatedItemProps extends Omit<UIFlexProps, 'ref'> {
	href: string
	isDark?: boolean
}
const RelatedItem = ({ children, href, isDark }: RelatedItemProps) => {
	return (
		<UIFlex
			{...{ component: UILink, href }}
			direction="row"
			alignItems="center"
			sx={{
				svg: {
					flexShrink: 0,
				},
			}}
		>
			<IconRelatedBracket color={isDark ? color.colWhite : color.colBlack} />
			<UIText
				className="relatedItem-text"
				lineClamp={1}
				color={isDark ? color.colWhite : color.colBlack}
				fontWeight="600"
				fontSize={{
					xs: pxToRem(15),
					md: pxToRem(14),
					lg: pxToRem(16),
					xl: pxToRem(17),
				}}
				lineHeight={{
					xs: pxToRem(22),
					md: pxToRem(20),
					lg: pxToRem(22),
					xl: pxToRem(24),
				}}
			>
				{children}
			</UIText>
		</UIFlex>
	)
}

export default RelatedItem

import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import Box from '@mui/joy/Box'
import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { SubscribeButton } from '@components/button/SubscribeButton'
import UIImage from '@components/ui/UIImage'
import { theme } from '@ui/style'

interface CornerItemProps {
	packageIdx?: string
	title: string
	description: string
	href: string
	src: string
	alt?: string
	isSubscribed?: boolean
}
const CornerItem = ({
	packageIdx,
	title,
	description,
	href,
	src,
	alt,
	isSubscribed,
}: CornerItemProps) => {
	return (
		<UIFlex
			alignItems="stretch"
			borderRadius={pxToRem(8)}
			overflow="hidden"
			sx={{ background: color.colWhite }}
			{...{ component: UILink, href }}
		>
			<Box width="100%">
				<UIImage
					src={src}
					alt={alt ?? ''}
					ratio={1}
					sx={{
						[theme.breakpoints.only('lg')]: {
							'.MuiAspectRatio-content': {
								paddingBottom: pxToRem(288),
							},
						},
					}}
				/>
			</Box>
			<UIFlex
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				padding={{
					xs: `${pxToRem(16)} ${pxToRem(20)}`,
					md: `${pxToRem(14)} ${pxToRem(18)}`,
					lg: `${pxToRem(16)} ${pxToRem(20)}`,
				}}
			>
				<UIFlex>
					<UIText lineClamp={1} level="HeadingM" color={color.colBlack}>
						{title}
					</UIText>
					<UIText
						lineClamp={1}
						level="CaptionTime"
						fontWeight={500}
						color={color.colGray5}
						mt={pxToRem(4)}
					>
						{description}
					</UIText>
				</UIFlex>
				<SubscribeButton
					target="package"
					idx={packageIdx?.toString()}
					isSubscribed={isSubscribed}
				/>
			</UIFlex>
		</UIFlex>
	)
}

export default CornerItem

import { CommonAreaResponseVo, HomeAreaSequenceResponseVo } from '@schemas/non-auth'
import Section from '@components/common/Section/Section'
import SectionTitle from '@components/common/Section/SectionTitle'
import UIContainer from '@components/ui/UIContainer'
import ListTemplate, { ArticleItemProps } from '@components/list'
import getTemplateData from '@utils/getTemplateData'
import { DATE_FORMAT, dayjs } from '@utils/date'
import { useLive } from '@services/api/NewsHome/Live/query'

export type LiveSectionData = HomeAreaSequenceResponseVo & CommonAreaResponseVo
interface SectionProps {
	data: LiveSectionData
	isLive?: boolean
}

const LiveSection = ({ data, isLive }: SectionProps) => {
	const { data: liveData } = useLive({ areaIdx: data.areaIdx })
	const { areaItemList, issueInfo, bgMoImgUrl, bgTbImgUrl, bgPcImgUrl, isStaticBackgroundColor } =
		data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }
	const getPublicTime = (item: ArticleItemProps) =>
		Number(dayjs(item.publicationDate).format(DATE_FORMAT.FULL_AS_NUMBER))
	const artcleList = getTemplateData(liveData?.areaItemList ?? areaItemList).sort(
		(a, b) => getPublicTime(b) - getPublicTime(a),
	)
	return (
		isLive &&
		artcleList.length > 0 && (
			<Section
				bgColor={isStaticBackgroundColor}
				{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
			>
				<UIContainer>
					{![2, 4, 22].includes(data.templateIdx) && (
						<SectionTitle
							href="/live"
							showGoButton
							isDark={!!src && !isStaticBackgroundColor}
							showLine={!src && !isStaticBackgroundColor}
						>
							실시간 라이브
						</SectionTitle>
					)}
					<ListTemplate
						type="live"
						list={artcleList}
						issue={issueInfo}
						isDark={!!src && !isStaticBackgroundColor}
						idx={data.templateIdx}
						areaIdx={data.areaIdx}
					/>
				</UIContainer>
			</Section>
		)
	)
}
export default LiveSection

'use client'

import { Box, BoxProps as MuiBoxProps } from '@mui/joy'
import CSSgridListRoot from './CSSgridListRoot'

// 목표
// 1.Box의 기능은 유지
// 2.display grid 를 기본으로 함
// 3.grid 옵션들은 별도의 로직을 넣지 않고 그대로 사용하도록 함
// 4.display grid Divider 사용이 곤란하므로 border를 유형별로 편리하게 넣을 수 있도록 함

interface CSSgridListProps extends MuiBoxProps {
	border?: 'horizontal' | 'vertical' | 'both'
	overflow?: MuiBoxProps['overflow']
	isDark?: boolean
	className?: string
}

const CSSgridList = ({
	children,
	sx,
	border,
	isDark,
	overflow = 'hidden',
	className,
	...props
}: CSSgridListProps) => {
	return (
		<Box overflow={overflow} className={className}>
			<CSSgridListRoot sx={sx} ownerState={{ border, isDark }} {...props}>
				{children}
			</CSSgridListRoot>
		</Box>
	)
}

export default CSSgridList

'use client'

import { useEffect, useState } from 'react'

interface IconGoIconMainIssueProps extends SVGComponentProps {
	size: 'xs' | 'md' | 'xl'
}

const iconConfigs = {
	xs: {
		width: 36,
		height: 36,
		viewBox: '0 0 36 36',
		d: 'M25 10L33 18L25 26',
		strokeWidth: 2.5,
	},
	md: {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		d: 'M8.5 5L15.5 12L8.5 19',
		strokeWidth: 2,
	},
	xl: {
		width: 32,
		height: 32,
		viewBox: '0 0 32 32',
		d: 'M22 8L30 16L22 24',
		strokeWidth: 2.5,
	},
}

export const IconGoIconMainIssue = ({ size, className }: IconGoIconMainIssueProps) => {
	const [current, setCurrent] = useState({
		width: 36,
		height: 36,
		viewBox: '0 0 36 36',
		d: 'M25 10L33 18L25 26',
		strokeWidth: 2.5,
	})
	const { width, height, viewBox, d, strokeWidth } = current
	useEffect(() => {
		setCurrent(iconConfigs[size])
	}, [size])
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox={viewBox}
			fill="none"
			className={className}
		>
			<path d={d} stroke="#242424" strokeWidth={strokeWidth} />
		</svg>
	)
}
